import 'firebase/firestore';

import * as firebase from 'firebase/app';

import { AgentAnnouncement, Announcement } from '../models/announcement';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { JobsService } from './jobs.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {
  agentAnnouncement$: Observable<AgentAnnouncement> = combineLatest([
    this.jobsService.currentJob$,
    this.authService.agent$
  ]).pipe(
    filter(([currentJob, agent]) => !!currentJob && !!agent),
    switchMap(([currentJob, agent]) =>
      this.afs
        .doc(`agentAnnouncements/${agent.uida}@${currentJob.id}`)
        .snapshotChanges()
        .pipe(
          map(snap => {
            if (snap.payload.exists) {
              return {
                ...(snap.payload.data() as AgentAnnouncement),
                from_server: true
              };
            }
            return {
              job_id: currentJob.id,
              agent_id: agent.uida,
              last_checked: null,
              from_server: false
            };
          })
        )
    ),
    // Setting a Firestore Timestamp causes the snapshotChanges to trigger
    // with a null value time while Google calculates the actual timestamp.
    // Then the snapshotChanges triggers again with a timestamp. This filter
    // prevents the null timestamp value server snapshotChanges from causing
    // announcement notifications to flicker/flash.
    filter(aa => !aa.from_server || !!aa.last_checked)
  );

  currentJobAnnouncements$: Observable<
    Announcement[]
  > = this.agentAnnouncement$.pipe(
    switchMap(aa =>
      this.afs
        .collection<Announcement>('announcements', ref =>
          ref.where('job_id', '==', aa.job_id).orderBy('created_at', 'desc')
        )
        .snapshotChanges()
        .pipe(map(snaps => ({ snaps, aa })))
    ),
    map(data => ({
      aa: data.aa,
      announcements: data.snaps.map(snap => ({
        ...snap.payload.doc.data(),
        id: snap.payload.doc.id
      }))
    })),
    map(data =>
      data.announcements.map(announcement => {
        announcement.unread =
          !data.aa.last_checked ||
          announcement.created_at > data.aa.last_checked;
        return announcement;
      })
    )
  );

  currentJobHasUnreadAnnouncements$: Observable<
    boolean
  > = this.agentAnnouncement$.pipe(
    switchMap((aa: AgentAnnouncement) => {
      if (aa.from_server && !aa.last_checked) {
        return of(false);
      }

      return this.afs
        .collection<Announcement>('announcements', ref => {
          let query = ref.where('job_id', '==', aa.job_id);
          if (aa.last_checked) {
            query = query.where('created_at', '>', aa.last_checked);
          }
          return query.orderBy('created_at', 'desc');
        })
        .snapshotChanges()
        .pipe(map(snaps => !!snaps.length));
    })
  );

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private jobsService: JobsService
  ) {}

  clearUnreadAnnouncements() {
    const job = this.jobsService.getCurrentJob();
    const agent = this.authService.getAgent();
    this.afs.doc(`agentAnnouncements/${agent.uida}@${job.id}`).set({
      job_id: job.id,
      agent_id: agent.uida,
      last_checked: firebase.firestore.FieldValue.serverTimestamp()
    });
  }
}
