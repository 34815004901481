import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.sass']
})
export class PageNotFoundComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    if (
      this.route.snapshot.url[0] &&
      this.route.snapshot.url[0].path === 'jobs'
    ) {
      this.router.navigate(['/accounts'], {
        queryParams: {
          legacyRedirect: this.route.snapshot.url
            .map(segment => segment.toString())
            .join('/')
        }
      });
    }
  }
}
