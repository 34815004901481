<!--Header with Company Logo-->
<img
  class="logo mx-auto d-none mt-lg-2 d-lg-block"
  [src]="domain?.logo"
  [alt]="'main.companyLogo' | translate"
  *ngIf="domain$ | async as domain"
/>

<!--Sidebar header top bar------------------------------------------------------>
<div class="sidebar-header d-flex align-items-center p-2 mw-100">
  <img
    class="mobile-logo d-lg-none px-2 mr-2"
    [src]="domain?.logoMobile"
    [alt]="'main.companyLogo' | translate"
    *ngIf="domain$ | async as domain"
  />

  <div *ngIf="jobSwitcherData$ | async as jobData" id="job-selector-container">
    <select
      id="job-selector"
      (change)="goToJob($event.target.value)"
      (click)="onJobSwitcherOpen()"
    >
      <option
        class="ed"
        *ngFor="let j of jobData.availableJobs"
        [value]="j.id"
        [selected]="j.id === jobData.currentJob?.id"
      >
        {{ j.name }}
      </option>
    </select>

    <div *ngIf="jobData.newJobCount" id="new-job-count-container">
      {{ jobData.newJobCount }}
    </div>
  </div>

  <!--Announcement Logo -->
  <a class="announcement-btn" (click)="goToAnnouncements()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      id="announcement-bell"
      class="align-items-center"
    >
      <path
        d="M207.1 16C207.1 7.164 215.2 0 223.1 0C232.8 0 240 7.164 240 16V32.79C320.9 40.82 384 109 384 192V221.1C384 264.8 401.4 306.7 432.3 337.7L435 340.4C443.3 348.7 448 359.1 448 371.7C448 396.2 428.2 416 403.7 416H44.28C19.83 416 0 396.2 0 371.7C0 359.1 4.666 348.7 12.97 340.4L15.72 337.7C46.63 306.7 64 264.8 64 221.1V192C64 109 127.1 40.82 208 32.79L207.1 16zM223.1 64C153.3 64 95.1 121.3 95.1 192V221.1C95.1 273.3 75.26 323.4 38.35 360.3L35.6 363C33.29 365.3 31.1 368.5 31.1 371.7C31.1 378.5 37.5 384 44.28 384H403.7C410.5 384 416 378.5 416 371.7C416 368.5 414.7 365.3 412.4 363L409.7 360.3C372.7 323.4 352 273.3 352 221.1V192C352 121.3 294.7 64 223.1 64H223.1zM223.1 480C237.9 480 249.8 471.1 254.2 458.7C257.1 450.3 266.3 445.1 274.6 448.9C282.9 451.9 287.3 461 284.4 469.3C275.6 494.2 251.9 512 223.1 512C196.1 512 172.4 494.2 163.6 469.3C160.7 461 165.1 451.9 173.4 448.9C181.7 445.1 190.9 450.3 193.8 458.7C198.2 471.1 210.1 480 223.1 480z"
      />
    </svg>
    <div
      *ngIf="hasAnnouncements$ | async"
      id="announcement-notification-dot-container"
    >
      <div id="announcement-notification-dot"></div>
    </div>
  </a>
</div>

<!--Side bar and Messages-->
<div class="left-side-bar d-flex h-100">
  <div class="sider-bar-container align-items-center d-flex flex-column">
    <div class="icon-list-section">
      <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="activeTab"
        class="justify-content-start icon-list"
        orientation="vertical"
      >
        <!--New convo button--------------------------------->
        <li id="start-new-button" class="d-none d-lg-flex">
          <a
            class="d-flex flex-column align-items-center"
            (click)="startNewConversation()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
              />
            </svg>

            {{ 'sidebar.startNew' | translate }}
          </a>
        </li>

        <!--Inbox button------------------------------------------------------->
        <li
          ngbNavItem="inboxtab"
          (mouseenter)="inboxTabIsHovered = true"
          (mouseleave)="inboxTabIsHovered = false"
        >
          <a
            class="sidebar-button d-flex flex-column align-items-center"
            [class.active]="activeTab === 'inboxtab'"
            ngbNavLink
          >
            <app-activity-badge
              countType="inbox"
              [activeTab]="activeTab === 'inboxtab' || inboxTabIsHovered"
            ></app-activity-badge>

            <!-- Inactive SVG -->
            <svg
              *ngIf="activeTab !== 'inboxtab'"
              class="svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M508.3 304.9l-61.25-248.7C443.5 42 430.7 31.1 416 31.1H96c-14.69 0-27.47 10-31.03 24.25L3.715 304.9C1.248 314.9 0 325.2 0 335.5v96.47c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48v-96.47C512 325.2 510.8 314.9 508.3 304.9zM96 64h319.1l55.18 224H368c-6.062 0-11.59 3.438-14.31 8.844L326.1 352H185.9L158.3 296.8C155.6 291.4 150.1 288 144 288H40.84L96 64zM480 432c0 8.822-7.178 16-16 16h-416C39.18 448 32 440.8 32 432v-96.47C32 330.3 33.04 325.2 33.88 320h100.2l27.58 55.16C164.4 380.6 169.9 384 176 384h160c6.062 0 11.59-3.438 14.31-8.844L377.9 320h100.2C478.1 325.2 480 330.3 480 335.5V432z"
              />
            </svg>

            <!-- Active SVG -->
            <svg
              *ngIf="activeTab === 'inboxtab'"
              class="svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M447 56.25C443.5 42 430.7 31.1 416 31.1H96c-14.69 0-27.47 10-31.03 24.25L3.715 304.9C1.247 314.9 0 325.2 0 335.5v96.47c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48v-96.47c0-10.32-1.247-20.6-3.715-30.61L447 56.25zM352 352H160L128 288H72.97L121 96h270l48.03 192H384L352 352z"
              />
            </svg>

            <div class="icon-text">
              {{ 'sidebar.inbox' | translate }}
            </div>
          </a>

          <ng-template ngbNavContent>
            <app-sidebar-conv-list
              [convs]="activeConvs$ | async"
              (scrolled)="onScroll('actionable')"
              [loading]="!convsService.finishedActionableDocs"
              [bottom]="convsService.finishedActionableDocs"
            ></app-sidebar-conv-list>
            <app-sidebar-empty-image
              *ngIf="isEmptyActiveList && convsService.finishedActionableDocs"
            ></app-sidebar-empty-image>
          </ng-template>
        </li>

        <!--Stop button------------------------------------------------------->
        <li ngbNavItem="stopstab" [destroyOnHide]="true">
          <!--Normal button------------------------------------------------------->
          <a
            class="sidebar-button d-flex flex-column align-items-center"
            [class.active]="activeTab === 'stopstab'"
            ngbNavLink
          >
            <svg
              *ngIf="activeTab !== 'stopstab'"
              class="svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 31.1c-141.4 0-255.1 93.13-255.1 208c0 47.63 19.91 91.25 52.91 126.3c-14.87 39.5-45.87 72.88-46.37 73.25c-6.623 7-8.373 17.25-4.623 26C5.816 474.3 14.38 480 24 480c61.49 0 109.1-25.75 139.1-46.25c28.1 9 60.16 14.25 92.9 14.25c141.4 0 255.1-93.13 255.1-208S397.4 31.1 256 31.1zM256 416c-28.25 0-56.24-4.25-83.24-12.75c-9.516-3.068-19.92-1.461-28.07 4.338c-22.1 16.25-58.54 35.29-102.7 39.66c11.1-15.12 29.75-40.5 40.74-69.63l.1289-.3398c4.283-11.27 1.791-23.1-6.43-32.82C47.51 313.1 32.06 277.6 32.06 240c0-97 100.5-176 223.1-176c123.5 0 223.1 79 223.1 176S379.5 416 256 416zM331.3 164.7c-6.25-6.25-16.38-6.25-22.62 0L256 217.4L203.3 164.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L233.4 240L180.7 292.7c-6.25 6.25-6.25 16.38 0 22.62c6.242 6.246 16.37 6.254 22.62 0L256 262.6l52.69 52.69c6.242 6.246 16.37 6.254 22.62 0c6.25-6.25 6.25-16.38 0-22.62L278.6 240l52.69-52.69C337.6 181.1 337.6 170.9 331.3 164.7z"
              />
            </svg>

            <svg
              *ngIf="activeTab === 'stopstab'"
              class="svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM175 159c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
              />
            </svg>

            <div class="icon-text">
              {{ 'sidebar.stops' | translate }}
            </div>
          </a>

          <ng-template ngbNavContent>
            <app-sidebar-conv-list
              [convs]="stopConvs$ | async"
              (scrolled)="onScroll('stop')"
              [loading]="!convsService.finishedStopDocs"
              [bottom]="convsService.finishedStopDocs"
            ></app-sidebar-conv-list>
            <app-sidebar-empty-image
              *ngIf="isEmptyStopList && convsService.finishedStopDocs"
              [type]="'stop'"
            ></app-sidebar-empty-image>
          </ng-template>
        </li>

        <!--All button------------------------------------------------------->
        <li ngbNavItem="alltab" [destroyOnHide]="true">
          <a
            class="sidebar-button d-flex flex-column align-items-center"
            [class.active]="activeTab === 'alltab'"
            ngbNavLink
          >
            <svg
              *ngIf="activeTab !== 'alltab'"
              class="svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                d="M80 160C88.84 160 96 152.8 96 144V64c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32h32c0-35.35-28.65-64-64-64H128C92.65 0 64 28.65 64 64v80C64 152.8 71.16 160 80 160zM576 96H256C220.7 96 192 124.7 192 160h32c0-17.67 14.33-32 32-32h320c17.67 0 32 14.33 32 32v192c0 17.67-14.33 32-32 32h-144c-8.836 0-16 7.162-16 16c0 8.836 7.164 16 16 16H576c35.35 0 64-28.65 64-64V160C640 124.7 611.3 96 576 96zM560 256C568.9 256 576 248.9 576 240v-64C576 167.1 568.9 160 560 160h-64C487.1 160 480 167.1 480 176v64C480 248.9 487.1 256 496 256H560zM512 192h32v32h-32V192zM320 192H64C28.65 192 0 220.7 0 256v192c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V256C384 220.7 355.3 192 320 192zM352 448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V313.6C44.75 323.3 64 339.3 128 384c12.75 9.625 38.38 32 64 32s51.25-22.38 64-32c64-44.75 83.25-60.75 96-70.38V448zM352 275.3c-25.62 19.12-22.38 19.12-115.3 86.38C227.3 364.8 208 384 192 384s-35.25-19.25-44.75-25.62C54.38 291.3 57.63 291.3 32 272V256c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32V275.3z"
              />
            </svg>

            <svg
              *ngIf="activeTab === 'alltab'"
              class="svg-icon "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                d="M191.9 448.6c-9.766 0-19.48-2.969-27.78-8.891L32 340.2V480c0 17.62 14.38 32 32 32h256c17.62 0 32-14.38 32-32v-139.8L220.2 439.5C211.7 445.6 201.8 448.6 191.9 448.6zM192 192c0-35.25 28.75-64 64-64h224V32c0-17.62-14.38-32-32-32H128C110.4 0 96 14.38 96 32v192h96V192zM320 256H64C46.38 256 32 270.4 32 288v12.18l151 113.8c5.25 3.719 12.7 3.734 18.27-.25L352 300.2V288C352 270.4 337.6 256 320 256zM576 160H256C238.4 160 224 174.4 224 192v32h96c33.25 0 60.63 25.38 63.75 57.88L384 416h192c17.62 0 32-14.38 32-32V192C608 174.4 593.6 160 576 160zM544 288h-64V224h64V288z"
              />
            </svg>

            <div class="icon-text">
              {{ 'sidebar.all' | translate }}
            </div>
          </a>

          <ng-template ngbNavContent>
            <app-sidebar-conv-list
              [convs]="allConvs$ | async"
              (scrolled)="onScroll('all')"
              [loading]="!convsService.finishedAllDocs"
              [bottom]="convsService.finishedAllDocs"
            ></app-sidebar-conv-list>
            <app-sidebar-empty-image
              *ngIf="isEmptyAllList && convsService.finishedAllDocs"
              [type]="'all'"
            ></app-sidebar-empty-image>
          </ng-template>
        </li>

        <!--More button------------------------------------------------------->
        <li>
          <button
            class="btn btn-link d-flex align-items-center mx-auto more-btn my-1"
            (click)="moreIsShowing = !moreIsShowing"
          >
            {{
              moreIsShowing
                ? ('sidebar.less' | translate)
                : ('sidebar.more' | translate)
            }}
            <svg
              class="more-icon ml-1"
              *ngIf="!moreIsShowing"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M432.6 209.3l-191.1 183.1C235.1 397.8 229.1 400 224 400s-11.97-2.219-16.59-6.688L15.41 209.3C5.814 200.2 5.502 184.1 14.69 175.4c9.125-9.625 24.38-9.938 33.91-.7187L224 342.8l175.4-168c9.5-9.219 24.78-8.906 33.91 .7187C442.5 184.1 442.2 200.2 432.6 209.3z"
              />
            </svg>

            <svg
              class="more-icon ml-1"
              *ngIf="moreIsShowing"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M15.41 302.7l191.1-183.1C212 114.2 218 111.1 224 111.1s11.97 2.219 16.59 6.688l191.1 183.1c9.594 9.152 9.906 24.34 .7187 33.9c-9.125 9.625-24.38 9.938-33.91 .7187L224 169.2l-175.4 168c-9.5 9.219-24.78 8.906-33.91-.7187C5.502 327 5.814 311.8 15.41 302.7z"
              />
            </svg>
          </button>
        </li>

        <!--Error button------------------------------------------------------->
        <li ngbNavItem="errortab" [destroyOnHide]="true">
          <a
            *ngIf="moreIsShowing"
            class="sidebar-button d-flex flex-column align-items-center"
            [class.active]="activeTab === 'errortab'"
            ngbNavLink
          >
            <svg
              *ngIf="activeTab !== 'errortab'"
              class="svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM416 416c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V96c0-17.64 14.36-32 32-32h320c17.64 0 32 14.36 32 32V416zM330.4 131.8c-6.688-5.75-16.8-4.938-22.55 1.75L224 231.4l-83.86-97.84C134.4 126.9 124.3 126.2 117.6 131.8c-6.719 5.75-7.5 15.88-1.734 22.56L202.9 256l-87.08 101.6c-5.766 6.688-4.984 16.81 1.734 22.56C120.6 382.8 124.3 384 128 384c4.5 0 8.984-1.906 12.14-5.594L224 280.6l83.86 97.84C311 382.1 315.5 384 320 384c3.688 0 7.391-1.281 10.41-3.844c6.719-5.75 7.5-15.88 1.734-22.56L245.1 256l87.08-101.6C337.9 147.7 337.1 137.6 330.4 131.8z"
              />
            </svg>

            <svg
              *ngIf="activeTab === 'errortab'"
              class="svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM330.3 344.5c8.562 10.11 7.297 25.27-2.828 33.83C323 382.1 317.5 384 312 384c-6.812 0-13.59-2.891-18.34-8.5L224 293.2L154.3 375.5C149.6 381.1 142.8 384 135.1 384C130.5 384 125 382.1 120.5 378.3c-10.12-8.562-11.39-23.72-2.828-33.83L192.6 256L117.7 167.5C109.1 157.4 110.4 142.2 120.5 133.7C130.6 125.1 145.8 126.4 154.3 136.5L224 218.8l69.67-82.34c8.547-10.12 23.72-11.41 33.83-2.828c10.12 8.562 11.39 23.72 2.828 33.83L255.4 256L330.3 344.5z"
              />
            </svg>

            <div class="icon-text">
              {{ 'sidebar.error' | translate }}
            </div>
          </a>

          <ng-template ngbNavContent>
            <app-sidebar-conv-list
              [convs]="errorConvs$ | async"
              (scrolled)="onScroll('error')"
              [loading]="!convsService.finishedErrorDocs"
              [bottom]="convsService.finishedErrorDocs"
            ></app-sidebar-conv-list>
            <app-sidebar-empty-image
              *ngIf="isEmptyErrorList && convsService.finishedErrorDocs"
              [type]="'error'"
            ></app-sidebar-empty-image>
          </ng-template>
        </li>

        <!--Drafts button------------------------------------------------------->
        <li ngbNavItem="pendingtab" [destroyOnHide]="true">
          <a
            *ngIf="moreIsShowing"
            class="sidebar-button d-flex flex-column align-items-center"
            [class.active]="activeTab === 'pendingtab'"
            ngbNavLink
          >
            <svg
              *ngIf="activeTab !== 'pendingtab'"
              class="svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M373.2 16.97C395.1-4.901 430.5-4.901 452.4 16.97L495 59.6C516.9 81.47 516.9 116.9 495 138.8L182.3 451.6C170.9 462.9 156.9 471.2 141.5 475.8L20.52 511.3C14.9 512.1 8.827 511.5 4.687 507.3C.5466 503.2-1.002 497.1 .6506 491.5L36.23 370.5C40.76 355.1 49.09 341.1 60.44 329.7L373.2 16.97zM429.8 39.6C420.4 30.22 405.2 30.22 395.8 39.6L341 94.4L417.6 170.1L472.4 116.2C481.8 106.8 481.8 91.6 472.4 82.23L429.8 39.6zM109.6 402.4L173.4 415.2L394.1 193.6L318.4 117L96.84 338.6L109.6 402.4zM70.51 370.2C69.08 373.2 67.88 376.3 66.93 379.5L39.63 472.4L132.4 445.1C135.7 444.1 138.8 442.9 141.8 441.5L92.86 431.7C86.53 430.4 81.58 425.5 80.31 419.1L70.51 370.2z"
              />
            </svg>
            <svg
              *ngIf="activeTab === 'pendingtab'"
              class="svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M421.7 220.3l-11.3 11.3-22.6 22.6-205 205c-6.6 6.6-14.8 11.5-23.8 14.1L30.8 511c-8.4 2.5-17.5 .2-23.7-6.1S-1.5 489.7 1 481.2L38.7 353.1c2.6-9 7.5-17.2 14.1-23.8l205-205 22.6-22.6 11.3-11.3 33.9 33.9 62.1 62.1 33.9 33.9zM96 353.9l-9.3 9.3c-.9 .9-1.6 2.1-2 3.4l-25.3 86 86-25.3c1.3-.4 2.5-1.1 3.4-2l9.3-9.3H112c-8.8 0-16-7.2-16-16V353.9zM453.3 19.3l39.4 39.4c25 25 25 65.5 0 90.5l-14.5 14.5-22.6 22.6-11.3 11.3-33.9-33.9-62.1-62.1L314.3 67.7l11.3-11.3 22.6-22.6 14.5-14.5c25-25 65.5-25 90.5 0z"
              />
            </svg>

            <div class="icon-text">
              {{ 'sidebar.pending' | translate }}
            </div>
          </a>

          <ng-template ngbNavContent>
            <app-sidebar-conv-list
              [convs]="pendingConvs$ | async"
              (scrolled)="onScroll('actionable')"
              [loading]="!convsService.finishedActionableDocs"
              [bottom]="convsService.finishedActionableDocs"
            ></app-sidebar-conv-list>
            <app-sidebar-empty-image
              *ngIf="isEmptyPendingList && convsService.finishedActionableDocs"
              [type]="'draft'"
            ></app-sidebar-empty-image>
          </ng-template>
        </li>
      </ul>
    </div>

    <div class="user-btn-section mt-auto">
      <button
        class="user-btn btn d-flex flex-column align-items-center"
        (click)="footerShowing = !footerShowing"
      >
        <svg
          class="profile-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c39.77 0 72 32.24 72 72S295.8 272 256 272c-39.76 0-72-32.24-72-72S216.2 128 256 128zM256 448c-52.93 0-100.9-21.53-135.7-56.29C136.5 349.9 176.5 320 224 320h64c47.54 0 87.54 29.88 103.7 71.71C356.9 426.5 308.9 448 256 448z"
          />
        </svg>

        <div class="user-name">
          {{ 'sidebar.user' | translate }}
        </div>
      </button>
    </div>
  </div>

  <div [ngbNavOutlet]="nav"></div>

  <button
    id="send-and-new-fab"
    class="btn btn-primary d-lg-none"
    [class.footer-showing]="footerShowing"
    (click)="createNewConv()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="new-icon"
    >
      <path
        d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
      />
    </svg>
  </button>
</div>

<app-user-menu class="down-menu" *ngIf="footerShowing"></app-user-menu>
