import { UiTheme, UserSettings } from '../models/user-settings';
import { filter, map, switchMap } from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { WINDOW } from '../window.provider';
import { Router } from '@angular/router';
import { DomainService } from './domain.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  currentSettings$ = new BehaviorSubject<UserSettings>(
    this.getDefaultUserSettings()
  );

  loginFromClassic = false;

  constructor(
    @Inject(WINDOW) private window: Window,
    private afs: AngularFirestore,
    private authService: AuthService,
    private domainService: DomainService,
    private languageService: LanguageService,
    private router: Router
  ) {
    this.initLocalSettings();
    this.subscribeToAgentSettings();
    this.subscribeToLanguageChanges();
  }

  setTheme(theme: UiTheme) {
    if (theme === UiTheme.dark) {
      document.body.classList.remove('light');
      document.body.classList.add('dark');
      this.domainService.changeLogoColor('dark');
    } else if (theme === UiTheme.light) {
      document.body.classList.remove('dark');
      document.body.classList.add('light');
      this.domainService.changeLogoColor('light');
    }
    // else {
    // TODO: Need to add system preferrence
    //   document.body.classList.remove('light');
    //   document.body.classList.remove('dark');
    // }

    if (theme !== this.currentSettings$.value?.theme) {
      this.updateUserSettings({ theme });
    }
  }

  setUiVersion(ui_version: number) {
    if (this.loginFromClassic && ui_version === 1) {
      this.loginFromClassic = false;
      return this.updateUserSettings({ ui_version: 2 });
    }

    return this.updateUserSettings({ ui_version }).then(() => {
      if (ui_version === 1) {
        let domain = this.domainService.getDomainUrl();

        if (domain === 'localhost') {
          domain = 'localhost:4200';
        } else if (domain === 'beta.peerly.dev') {
          domain = domain.replace('beta.', '');
        } else {
          domain = domain.replace('faster.', '');
        }

        const path = this.domainService.getCurrentURLPath();

        this.authService.getAgentUILink(domain, path).then(url => {
          if (url) {
            this.window.location.href = url;
          }
        });
      }
    });
  }

  updateUserSettings(settings: Partial<UserSettings>): Promise<void> {
    const update = Object.assign({ ...this.currentSettings$.value }, settings);
    this.window.localStorage.setItem('agentSettings', JSON.stringify(update));

    if (!this.authService.agent.value) {
      this.currentSettings$.next(update);
      return Promise.resolve();
    }
    return this.afs
      .doc(`agent_settings/${this.authService.agent.value.id}`)
      .update(settings);
  }

  private getDefaultUserSettings(): UserSettings {
    return {
      preferred_language: this.languageService.getCurrentLanguage(),
      theme: UiTheme.light,
      ui_version: 2
    };
  }

  private setLanguage(lang: string) {
    if (lang !== this.languageService.getCurrentLanguage()) {
      this.languageService.setCurrentLanguage(lang);
    }
  }

  private initLocalSettings() {
    if (!this.window.localStorage.getItem('agentSettings')) {
      return;
    }

    const settings = JSON.parse(
      this.window.localStorage.getItem('agentSettings')
    );

    this.setTheme(settings.theme);
    this.setLanguage(settings.preferred_language);
    this.setUiVersion(settings.ui_version);
  }

  private subscribeToAgentSettings() {
    this.authService.agent$
      .pipe(
        filter(agent => !!agent),
        switchMap(agent =>
          this.afs.doc(`agent_settings/${agent.id}`).snapshotChanges()
        ),
        map(changeAction => {
          const snapshot = changeAction.payload;
          if (!snapshot.exists) {
            return this.afs
              .doc(`agent_settings/${snapshot.id}`)
              .set(this.getDefaultUserSettings());
          }

          const newSettings = snapshot.data() as UserSettings;
          this.currentSettings$.next(newSettings);
          this.setUiVersion(newSettings.ui_version);
          this.setTheme(newSettings.theme);
          this.setLanguage(newSettings.preferred_language);
        })
      )
      .subscribe();
  }

  private subscribeToLanguageChanges() {
    this.languageService.currentLanguage$.subscribe((lang: string) => {
      if (lang && lang !== this.currentSettings$.value?.preferred_language) {
        this.updateUserSettings({ preferred_language: lang });
      }
    });
  }
}
