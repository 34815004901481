import {
  catchError,
  distinctUntilChanged,
  map,
  switchMap
} from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, config, Observable, of } from 'rxjs';
import { DefaultDomain, Domain } from '../models/domain';
import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../window.provider';
import { environment } from 'src/environments/environment';
import { Alert } from '../models/alert';
import { HttpClient } from '@angular/common/http';

interface UIVersion {
  major: number;
  minor: number;
  bug: number;
}

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  BASE_FS_URL = environment.afConfig.assetStorageURL;
  defaultDomain = new BehaviorSubject<DefaultDomain>(null);
  domain = new BehaviorSubject<Domain>(null);
  domain$: Observable<Domain> = this.domain
    .asObservable()
    .pipe(distinctUntilChanged());
  hasFetched = false;
  disabledByConfig = new BehaviorSubject<boolean>(false);
  systemConfigs: Observable<DefaultDomain> = combineLatest([
    this.domain,
    this.defaultDomain
  ]).pipe(
    map(([domain, defaultDomain]) => {
      let canEditMessages = 'ALL';
      if (
        domain?.can_edit_messages !== null &&
        domain?.can_edit_messages !== undefined
      ) {
        canEditMessages = domain.can_edit_messages;
      } else if (
        defaultDomain?.can_edit_messages !== null &&
        defaultDomain?.can_edit_messages !== undefined
      ) {
        canEditMessages = defaultDomain.can_edit_messages;
      }

      return {
        ready_interval:
          domain?.ready_interval || defaultDomain?.ready_interval || 300,
        system_message:
          domain?.system_message ||
          defaultDomain?.system_message ||
          ({} as Alert),
        disabled: domain?.disabled || defaultDomain?.disabled || false,
        minimum_ui_version:
          domain?.minimum_ui_version ||
          defaultDomain?.minimum_ui_version ||
          null,
        // TODO: This will need to be removed or made better when v2 goes to main prod
        minimum_beta_ui_version:
          domain?.minimum_beta_ui_version ||
          defaultDomain?.minimum_beta_ui_version ||
          null,
        can_edit_messages: canEditMessages
      };
    }),
    map((configs: DefaultDomain) => {
      // TODO: This was changed to check beta version, but will need to be changed when v2 goes to main prod
      if (configs && configs.minimum_beta_ui_version) {
        console.log('checking beta ui ver', configs.minimum_beta_ui_version);
        configs.has_minimum_ui = this.hasMinimumUIVersion(
          configs.minimum_beta_ui_version
        );
      }
      return configs;
    })
  );

  isCoreDomain = false;
  currentTheme = 'light';

  constructor(
    @Inject(WINDOW) private window: Window,
    private afs: AngularFirestore,
    private http: HttpClient
  ) {
    this.subscribeToDefaultDomain();
    this.isCoreDomain = this.checkIsCoreDomain();
  }

  subscribeToDomain(domainId: string) {
    return this.afs
      .doc<Domain>(`domains/${domainId}`)
      .valueChanges()
      .pipe(
        map(domainData => {
          domainData.static_assets_location = domainData.static_assets_location
            ? domainData.static_assets_location
            : domainData.aid;
          return domainData;
        }),
        switchMap(domainData => this.setLogoUrls(domainData))
      )
      .subscribe(domainData => {
        console.log(`Setting domain`, domainData);
        this.domain.next(domainData);

        this.buildDomainAppManifest(
          domainData.app_name,
          domainData.app_short_name,
          domainData.app_description,
          domainData.static_assets_location
        );
        this.setAppleTouchIcon(domainData.static_assets_location);
        this.setFavicon(domainData.static_assets_location);
        this.setSiteTitle(domainData.app_name);
      });
  }

  subscribeToDefaultDomain() {
    console.log('Fetching default domain');
    this.afs
      .doc(`domains/default`)
      .valueChanges()
      .subscribe((defaultDomain: DefaultDomain) => {
        console.log('Got default domain', { defaultDomain });
        this.defaultDomain.next(defaultDomain);
      });
  }

  fetchDomainData(domain: string): Observable<Domain> {
    if (this.domain.value) {
      console.log('Domain already set');
      return this.domain$;
    }

    console.log(`Fetching domain for ${domain}`);
    return this.afs
      .doc<Domain>(`domains/${domain}`)
      .get()
      .pipe(
        map(snapshot => {
          if (!snapshot.exists) {
            console.warn(`Failed to find matching domain data for: ${domain}`);
            throw Error('No domain document');
          }
          const domainData = snapshot.data() as Domain;
          this.subscribeToDomain(domain);
          return domainData;
        })
      );
  }

  buildDomainAppManifest(
    appName: string,
    appShortName: string,
    appDescription: string,
    assetsLocation: string
  ) {
    const dynamicManifest = {
      name: appName,
      short_name: appShortName,
      description: appDescription,
      start_url: '/',
      background_color: '#fafafa',
      theme_color: '#1976d2',
      icons: this.buildIconList(assetsLocation)
    };
    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector('#manifest-placeholder')
      .setAttribute('href', manifestURL);
  }

  changeLogoColor(theme: string) {
    this.currentTheme = theme;
    const domain = this.domain.value;

    if (!domain) {
      return;
    }

    if (theme === 'dark') {
      domain.logo = domain.logo.replace('-light', '-dark');
      domain.logoMobile = domain.logoMobile.replace('-light', '-dark');
    } else if (theme === 'light') {
      domain.logo = domain.logo.replace('-dark', '-light');
      domain.logoMobile = domain.logoMobile.replace('-dark', '-light');
    }
    this.domain.next(domain);
  }

  getAccountDomainString(accountId: string) {
    return `${accountId}.${this.getRootDomain()}`;
  }

  getCurrentURLPath(): string {
    return this.window.location.pathname;
  }

  getDomainUrl() {
    return this.window.location.hostname;
  }

  getRootDomain() {
    return this.window.location.hostname
      .split('.')
      .reverse()
      .splice(0, 2)
      .reverse()
      .join('.');
  }

  checkIsCoreDomain(): boolean {
    return ['peerly.dev', 'peerly.app', 'saay.app', '121texting.com'].includes(
      this.getRootDomain()
    );
  }

  private buildIconList(location: string) {
    return [
      '36xx36',
      '48x48',
      '72x72',
      '96x96',
      '128x128',
      '144x144',
      '152x152',
      '192x192',
      '384x384',
      '512x512'
    ].map(size => {
      return `${this.BASE_FS_URL}${location}%2Ficon-${size}.png?alt=media`;
    });
  }

  setAppleTouchIcon(location: string) {
    document
      .querySelector('#apple-touch-icon-placeholder')
      .setAttribute(
        'href',
        `${this.BASE_FS_URL}${location}%2Ficon-192x192.png?alt=media`
      );
  }

  setFavicon(location: string) {
    document
      .querySelector('#favicon-placeholder')
      .setAttribute(
        'href',
        `${this.BASE_FS_URL}${location}%2Ffavicon.ico?alt=media`
      );
  }

  setSiteTitle(title: string) {
    document.querySelector('#site-title-placeholder').innerHTML = title;
  }

  private setLogoUrls(domainData: Domain): Observable<Domain> {
    // Setting default logos to svg version
    domainData.logo = `${this.BASE_FS_URL}${domainData.static_assets_location}%2Flogo-${this.currentTheme}.svg?alt=media`;
    domainData.logoMobile = `${this.BASE_FS_URL}${domainData.static_assets_location}%2Flogo-small-${this.currentTheme}.svg?alt=media`;

    return this.getLogoUrl(domainData.logo).pipe(
      switchMap(bigLogoUrl =>
        this.getLogoUrl(domainData.logoMobile).pipe(
          map(smallLogoUrl => {
            domainData.logo = bigLogoUrl;
            domainData.logoMobile = smallLogoUrl;
            return domainData;
          })
        )
      )
    );
  }

  private getLogoUrl(url: string): Observable<string | null> {
    return this.http.get(url).pipe(
      map(() => url),
      catchError(firstTryErr => {
        if (firstTryErr.status === 200) {
          return of(url);
        }

        url = url.replace('.svg?', '.png?');
        return this.http.get(url).pipe(
          map(() => url),
          catchError(secondTryErr => {
            if (secondTryErr.status === 200) {
              return of(url);
            }
            return of(null);
          })
        );
      })
    );
  }

  private hasMinimumUIVersion(minimumUI: string): boolean {
    console.log('checking min ui ver', minimumUI);
    const currentUI = this.parseUIVersion(environment.version);
    const requiredUI = this.parseUIVersion(minimumUI);
    if (!currentUI || !requiredUI) {
      return true;
    }

    const majorIsGreater = currentUI.major > requiredUI.major;
    const minorIsGreater =
      currentUI.major === requiredUI.major &&
      currentUI.minor > requiredUI.minor;
    const bugIsGreater =
      currentUI.major === requiredUI.major &&
      currentUI.minor === requiredUI.minor &&
      currentUI.bug >= requiredUI.bug;

    return majorIsGreater || minorIsGreater || bugIsGreater;
  }

  private parseUIVersion(version: string): UIVersion {
    const parsed = {} as UIVersion;
    const parts = version.split('.');
    parsed.major = +parts[0];
    parsed.minor = +parts[1];
    parsed.bug = +parts[2].split('-')[0];
    return parsed;
  }
}
