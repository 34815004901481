import {
  ActivatedRouteSnapshot,
  CanActivate,
  Params,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { map, switchMap } from 'rxjs/operators';

import { Agent } from '../models/agent';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.afAuthUser$.pipe(
      switchMap((fbUser: firebase.User) =>
        this.authService.agent$.pipe(
          map((agent: Agent) => {
            if (!fbUser) {
              console.log('No auth user');
              this.routeToLogin(next.params);
              return false;
            } else if (!agent) {
              console.log('Auth guard waiting for agent data');
              this.authService.onLogin(
                fbUser,
                next.params.accountId,
                state.url
              );
              return false;
            } else if (agent.status !== 'active') {
              console.log('Auth guard blocked routing: inactive agent');
              this.authService.signOut();
              this.routeToLogin(next.params);
              return false;
            }
            console.log('Let this agent enter');
            return true;
          })
        )
      )
    );
  }

  private routeToLogin(params: Params) {
    if (params.accountId) {
      this.router.navigate(['/accounts', params.accountId, 'login']);
    } else {
      this.router.navigate(['/accounts']);
    }
  }
}
