import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  Conversation,
  QuestionBase,
  SelectQuestion
} from '../models/conversation';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  takeWhile,
  tap
} from 'rxjs/operators';

import { ConvsService } from '../services/convs.service';
import { JobsService } from '../services/jobs.service';
import { QuestionResponseEvent } from '../models/event';
import { Template } from '../models/job';

@Component({
  selector: 'app-conv-sidebar-questions',
  templateUrl: './conv-sidebar-questions.component.html',
  styleUrls: ['./conv-sidebar-questions.component.scss'],
  animations: [
    trigger('saveQuestion', [
      state(
        'pristine',
        style({
          backgroundColor: 'var(--agent-gray-1-color)'
        })
      ),
      state(
        'pending',
        style({
          backgroundColor: 'rgba(200, 200, 200, .5)'
        })
      ),
      state(
        'invalid',
        style({
          backgroundColor: 'rgba(255, 65, 54, .2)'
        })
      ),
      state(
        'saved',
        style({
          backgroundColor: 'rgba(40, 182, 44, .2)'
        })
      ),
      transition('* => pending', [animate('500ms')]),
      transition('* => saved', [animate('500ms')]),
      transition('* => invalid', [animate('500ms')]),
      transition('* => pristine', [animate('500ms')])
    ])
  ]
})
export class ConvSidebarQuestionsComponent implements OnDestroy, OnInit {
  currentConversation$: Observable<Conversation>;
  conversation: Conversation;
  error: string;
  form: FormGroup;
  formQuestions: QuestionBase<any>[] = [];
  qStates: Record<string, string> = {};
  qErrors: Record<string, string> = {};
  qValues: Record<
    string,
    { fc: FormControl; q: QuestionBase<any>; value: string }
  > = {};
  specialCharRegex = new RegExp('[\\./]', 'gm');
  subs: Subscription[] = [];
  updatedResponseEvents: QuestionResponseEvent[] = [];
  alive = false;

  @Output() templateSelected = new EventEmitter<Template>();

  constructor(
    private convsService: ConvsService,
    private jobsService: JobsService
  ) {}

  ngOnDestroy() {
    this.alive = false;

    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
    this.qStates = {};
    this.qErrors = {};

    this.flushPendingQuestions();
  }

  ngOnInit() {
    this.alive = true;

    this.currentConversation$ = this.convsService.currentConversation$.pipe(
      filter(Boolean),
      map(conv => conv as Conversation),
      tap(conv => {
        if (this.conversation && conv.id !== this.conversation.id) {
          this.flushPendingQuestions();
        }

        this.conversation = conv;
      }),
      distinctUntilChanged((prev, current) => {
        // We only want to update this if the questions/answers have changed or if the conversation has changed.
        // It feels backwards, but distinctUntilChanged emits on false, not true.
        return (
          prev.id === current.id &&
          JSON.stringify(prev.questions) === JSON.stringify(current.questions)
        );
      }),
      tap(conv => this.buildForm())
    );
  }

  buildForm() {
    this.formQuestions = this.conversation
      ? (JSON.parse(
          JSON.stringify(this.conversation.questions)
        ) as QuestionBase<any>[]).map(question => {
          if (question.controlType === 'select') {
            // If the question response(s) is/are a string, we need to remove periods and slashes from
            // the response string so it doesn't interfere with Firestore ID path values.
            return this.stripSpecialChars(question as SelectQuestion);
          }
          return question;
        })
      : [];
    this.form = this.toFormGroup(this.formQuestions);
    console.log(this.formQuestions);
  }

  private clearSelectQuestionTemplate(question: SelectQuestion) {
    const resp = question.responses.find(r => r.text === question.response);

    if (!resp || !resp.template) {
      return;
    }

    const template = this.jobsService.getTemplateById(resp.template);
    this.convsService.clearActiveTemplate(template.text);
  }

  private handleQuestionSave(
    fc: FormControl,
    q: QuestionBase<any>,
    value: string,
    populateTemplate: boolean = false
  ) {
    if (value === null || !fc.valid) {
      console.log('value invalid:', q.id, q.title, value);
      this.qStates[q.id] = 'invalid';
      if (q.controlType === 'number') {
        this.qErrors[q.id] = 'Entry must be a number.';
      } else {
        this.qErrors[q.id] = 'Invalid value.';
      }
      delete this.qValues[q.id];
    } else if (value === '') {
      console.log('value removed:', q.id, q.title, value);

      console.log({ ...q });
      if (q.controlType === 'select') {
        this.clearSelectQuestionTemplate({ ...q } as SelectQuestion);
      }

      this.qStates[q.id] = 'pristine';
      this.qErrors[q.id] = '';
      delete this.qValues[q.id];
      q.response = value;
      this.convsService.updateConversationQuestion(this.conversation, q);
    } else {
      console.log('value change:', q.id, q.title, value);
      this.qStates[q.id] = 'saved';
      this.qErrors[q.id] = '';
      delete this.qValues[q.id];
      q.response = value;
      this.convsService.updateConversationQuestion(this.conversation, q);

      if (populateTemplate) {
        let tmplId = q.template;
        if (q.controlType === 'select') {
          console.log(q, value);
          const resps = (q as SelectQuestion).responses.filter(
            r => r.text === value
          );
          if (resps.length > 0 && resps[0].template) {
            console.log('found resp', resps[0]);
            tmplId = resps[0].template;
          } else {
            tmplId = '';
          }
        }

        if (tmplId) {
          const tmpl = this.jobsService.getTemplateById(tmplId);
          if (tmpl) {
            this.templateSelected.emit(tmpl);
          }
        }
      }
    }
  }

  private toFormGroup(questions: QuestionBase<any>[]) {
    const group = {};
    questions.forEach(q => {
      const fc = new FormControl(q.response || '');

      this.subs.push(
        fc.valueChanges
          .pipe(
            distinctUntilChanged(),
            map(value => {
              return value && q.controlType === 'number' ? +value : value;
            }),
            tap(value => {
              this.qStates[q.id] = 'pending';
              this.qValues[q.id] = { fc, q, value };
            }),
            debounceTime(q.controlType === 'select' ? 0 : 2000),
            takeWhile(() => this.qValues[q.id] !== undefined)
          )
          .subscribe(value => {
            console.log('Question state:', this.qStates[q.id]);
            this.handleQuestionSave(fc, q, value, true);
          })
      );

      group[q.id] = fc;
      this.qStates[q.id] =
        'null' + (q.response || '') === 'null' ? 'pristine' : 'saved';
    });
    return new FormGroup(group);
  }

  private flushPendingQuestions() {
    for (const key in this.qValues) {
      if (!this.qValues.hasOwnProperty(key)) {
        continue;
      }
      const val = this.qValues[key];
      this.handleQuestionSave(val.fc, val.q, val.value);
    }
    this.qValues = {};
  }

  private isStr(value: any): boolean {
    return Object.prototype.toString.call(value) === '[object String]';
  }

  private stripSpecialChars(question: SelectQuestion): SelectQuestion {
    // If the question response(s) is/are a string, we need to remove periods and slashes from
    // the response string so it doesn't interfere with Firestore ID path values.
    if (question.response) {
      question.response = question.response.replace(this.specialCharRegex, '');
    }

    if (question.responses) {
      question.responses = question.responses.map(res => {
        res.text = res.text.replace(this.specialCharRegex, '');
        return res;
      });
    }
    return question;
  }
}
