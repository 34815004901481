import * as firebase from 'firebase/app';

import { Subscription, combineLatest } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

import { Agent } from '../models/agent';
import { AuthService } from './auth.service';
import { ConvsService } from './convs.service';
import { Injectable } from '@angular/core';
import { Job } from '../models/job';
import { JobsService } from './jobs.service';
import { StatsService } from './stats.service';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root'
})
export class TransferService {
  requestAutoTransfersCloudFunction = firebase
    .functions()
    .httpsCallable('conversations-requestAutoTransfers');

  actionableConvos$ = this.convsService.actionableConversations$.pipe(
    throttleTime(10000),
    map(convs => convs.length)
  );
  currentAgent$ = this.authService.agent$;
  currentJob$ = this.jobsService.currentJob$;

  autoTransferTimer: NodeJS.Timer = null;
  backoffCount = 1;
  currentAgent: Agent;
  currentCount: number;
  currentJob: Job;
  running = false;
  sub: Subscription;

  constructor(
    private authService: AuthService,
    private convsService: ConvsService,
    private jobsService: JobsService,
    private statsService: StatsService,
    private timeService: TimeService
  ) {
    // console.log('Init transfer service');

    this.requestAutoTransfers = this.requestAutoTransfers.bind(this);

    this.sub = combineLatest([
      this.actionableConvos$,
      this.currentAgent$,
      this.currentJob$
    ]).subscribe(([actionableConvosCount, currentAgent, currentJob]) => {
      if (!currentAgent || !currentJob) {
        this.stopAutoTransfers();
        return;
      }

      this.currentCount = actionableConvosCount;
      this.currentAgent = currentAgent;
      this.currentJob = currentJob;
      const agentAssignment = currentJob.agents[currentAgent.id];

      if (currentJob.useAutoTransfers && agentAssignment.is_responder) {
        this.startAutoTransfers();
      } else {
        this.stopAutoTransfers();
      }
    });
  }

  signOut() {
    this.stopAutoTransfers();
    this.currentAgent = null;
    this.currentJob = null;
    this.currentCount = 0;
  }

  private startAutoTransfers() {
    if (!this.running) {
      console.log('Job uses auto transfers. Activating auto transfers.');
      this.requestAutoTransfers();
      this.running = true;
    }
  }

  private stopAutoTransfers() {
    if (this.autoTransferTimer) {
      clearTimeout(this.autoTransferTimer);
      this.autoTransferTimer = null;
    }
    this.running = false;
  }

  private requestAutoTransfers() {
    const tenMinAgo = this.timeService.now.getTime() - 10 * 60 * 1000;
    const lastStatus = this.statsService.getCurrentStatus(true);
    const isActive = lastStatus.last_action_date >= tenMinAgo;

    if (this.currentAgent && isActive && this.currentCount < 50) {
      const reqNumber = 50 - this.currentCount;
      this.requestAutoTransfersCloudFunction({
        jobId: this.currentJob.id,
        agent: this.currentAgent,
        numberRequested: reqNumber
      }).then(response => {
        const count = response.data;

        if (count === 0) {
          this.backoffCount = Math.min(this.backoffCount + 1, 8);
        } else {
          this.backoffCount = 1;
        }

        this.autoTransferTimer = setTimeout(
          this.requestAutoTransfers,
          this.backoffCount * 15 * 1000
        );
      });
    }
  }
}
