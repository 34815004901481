import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, combineLatest } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { DomainService } from '../services/domain.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  data$ = combineLatest([
    this.domainService.domain$,
    this.route.queryParams
  ]).pipe(
    map(([domain, queryParams]) => {
      return { domain, code: queryParams.code, email: queryParams.email };
    })
  );
  message: string;
  messageType = 'danger';
  form: FormGroup;
  isWelcome = false;
  submitting = false;

  constructor(
    private auth: AuthService,
    private domainService: DomainService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.form = this.fb.group({
      password: [null, Validators.required],
      confirm: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.isWelcome = this.router.url.includes('register');
  }

  onSubmit(code, email) {
    console.table(this.form.value);
    this.message = null;
    this.submitting = true;
    const { password, confirm } = this.form.value;

    try {
      if (password !== confirm) {
        throw new Error('reset.mustMatch');
      }

      if (!code || !email) {
        throw new Error('reset.invalidCode');
      }

      this.auth
        .resetPassword(
          code,
          email,
          password,
          this.domainService.getRootDomain()
        )
        .then(() => {
          this.message = 'reset.success';
          this.messageType = 'success';
          setTimeout(() => {
            this.router.navigate(['../login']);
          }, 2000);
        })
        .catch(error => {
          this.message = error.message;
        })
        .finally(() => (this.submitting = false));
    } catch (e) {
      console.error(e);
      this.message = e.message;
      this.submitting = false;
    }
  }
}
