import { BehaviorSubject, Observable, combineLatest, timer } from 'rxjs';
import { Component, EventEmitter, Output } from '@angular/core';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';

import { JobsService } from '../services/jobs.service';
import { Template } from '../models/job';

@Component({
  selector: 'app-conv-sidebar-templates',
  templateUrl: './conv-sidebar-templates.component.html',
  styleUrls: ['./conv-sidebar-templates.component.scss']
})
export class ConvSidebarTemplatesComponent {
  search = new BehaviorSubject<string>(null);
  searchTrigger$ = this.search.pipe(
    debounce(search => {
      if (search) {
        return timer(500);
      }
      return timer(0);
    }),
    distinctUntilChanged()
  );
  templates$: Observable<Template[]> = this.jobsService.currentJob$.pipe(
    map(j => {
      const defaultTemplate = j.templates.find(t => t.is_default);
      const newTemplates = j.templates.filter(t => !t.is_default);
      newTemplates.push(defaultTemplate);
      return newTemplates;
    })
  );

  filteredTemplates$ = combineLatest([
    this.searchTrigger$,
    this.templates$
  ]).pipe(
    map(([search, templates]) => {
      if (!search) {
        return templates;
      }
      return templates.filter(template =>
        template.text.toLowerCase().includes(search.toLowerCase())
      );
    })
  );

  @Output() selected = new EventEmitter<Template>();

  constructor(private jobsService: JobsService) {}

  onSearch(e: any) {
    this.search.next(e.target.value);
  }

  onTemplateSelection(template: Template) {
    this.selected.emit(template);
  }
}
