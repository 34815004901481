import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { DomainService } from '../services/domain.service';
import { Subscription } from 'rxjs';
import { UserSettingsService } from '../services/user-settings.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnDestroy, OnInit {
  sub: Subscription;
  loading = true;
  error = false;

  constructor(
    private authService: AuthService,
    private domainService: DomainService,
    private route: ActivatedRoute,
    private router: Router,
    private userSettingsService: UserSettingsService
  ) {
    this.sub = this.route.queryParamMap
      .pipe(
        map(params => {
          const token = params.get('token');
          const path = params.get('path');
          const version = params.get('version');
          return { token, path, version };
        }),
        switchMap(params =>
          this.domainService.domain$.pipe(
            filter(domain => !!domain),
            map(domain => ({
              aid: domain.aid,
              token: params.token,
              path: params.path,
              version: params.version
            }))
          )
        )
      )
      .subscribe(params =>
        this.login(params.token, params.aid, params.path, params.version)
      );
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit(): void {}

  private login(token, aid, path, version) {
    console.log('Loging in admin');
    this.userSettingsService.loginFromClassic = true;

    this.authService
      .loginWithCustomToken(token, aid)
      .catch(e => {
        console.error(e);
        this.loading = false;
        this.error = true;
      })
      .then(() => {
        if (version) {
          this.userSettingsService.setUiVersion(+version);
        }

        if (path) {
          this.router.navigateByUrl(path);
        }
      });
  }
}
