import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';

import { Agent } from '../models/agent';
import { AuthService } from '../services/auth.service';
import { Domain } from '../models/domain';
import { DomainService } from '../services/domain.service';
import { Job } from '../models/job';
import { JobsService } from '../services/jobs.service';
import { Router } from '@angular/router';
import { SignoutService } from '../services/signout.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pending-agent-page',
  templateUrl: './pending-agent-page.component.html',
  styleUrls: ['./pending-agent-page.component.scss']
})
export class PendingAgentPageComponent implements OnDestroy, OnInit {
  subs: Subscription[] = [];
  domain: Domain;
  agent: Agent;
  version: string = environment.version;

  constructor(
    private authService: AuthService,
    private domainService: DomainService,
    private jobsService: JobsService,
    private router: Router,
    private signoutService: SignoutService
  ) {}

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    const s1 = this.jobsService.availableJobs$.subscribe((jobs: Job[]) => {
      if (jobs.length > 0) {
        console.log('Jobs found, navigate away from pending to default');
        this.reloadApp(jobs[0].id);
      }
    });
    this.subs.push(s1);
    const s2 = this.domainService.domain$
      .pipe(filter(Boolean), take(1))
      .subscribe((d: Domain) => (this.domain = d));
    this.subs.push(s2);
    const s3 = this.authService.agent$
      .pipe(filter(Boolean), take(1))
      .subscribe((a: Agent) => (this.agent = a));
    this.subs.push(s3);
  }

  signOut() {
    this.signoutService.signout();
  }

  private reloadApp(jobId?: string) {
    this.router.navigate(['jobs', jobId ? jobId : 'default']);
  }
}
