import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { Announcement } from '../models/announcement';

@Component({
  selector: 'app-home-page-announcements',
  templateUrl: './home-page-announcements.component.html',
  styleUrls: ['./home-page-announcements.component.scss']
})
export class HomePageAnnouncementsComponent implements OnDestroy, OnInit {
  @Input() announcements: Announcement[];
  @Output() newConvo = new EventEmitter<void>();
  @Output() read = new EventEmitter<void>();
  timer: NodeJS.Timeout;

  constructor() {}

  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  ngOnInit() {
    this.timer = setTimeout(() => {
      this.read.emit();
    }, 5000);
  }

  createNewConv() {
    this.newConvo.emit();
  }
}
