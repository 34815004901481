import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KeyClickService {
  pressedKeys: { [key: string]: boolean } = {};
  constructor() {}

  handleKeyClick(event: KeyboardEvent) {
    if (!(event.key in this.pressedKeys)) {
      this.pressedKeys[event.key] = true;
    }
  }

  handleKeyClickUp(event: KeyboardEvent) {
    delete this.pressedKeys[event.key];
  }

  clickedKey(key: string) {
    return key in this.pressedKeys;
  }

  hasCtrlAndEnter(): boolean {
    return 'Control' in this.pressedKeys && 'Enter' in this.pressedKeys;
  }
}
