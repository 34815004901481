import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { map, tap } from 'rxjs/operators';

import { AlertsService } from '../services/alerts.service';
import { AuthService } from '../services/auth.service';
import { ConvsService } from '../services/convs.service';
import { DomainService } from '../services/domain.service';
import { KeyClickService } from '../services/keyclick.service';
import { SidebarService } from '../services/sidebar.service';
import { SignoutService } from '../services/signout.service';
import { SpinnerService } from '../services/spinner.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { TransferService } from '../services/transfer.service';
import { combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { isSmallScreenWidth } from '../utils/screens';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))])
    ])
  ]
})
export class MainComponent implements OnDestroy, OnInit {
  @ViewChild('confirmLogoutSwal', { static: false }) swal: SwalComponent;

  agent$ = this.authService.agent$;
  domain$ = this.domainService.domain$;
  footerData$ = combineLatest([this.agent$, this.domain$]).pipe(
    map(([agent, domain]) => ({
      agentEmail: agent ? agent.display_email : '',
      appName: domain ? domain.app_name : ''
    }))
  );

  sidebarIsClosed$ = this.sidebarService.sidebarIsClosed$;

  version: string = environment.version;

  get isSmallScreenWidth() {
    return isSmallScreenWidth();
  }

  constructor(
    public alertsService: AlertsService,
    public spinnerService: SpinnerService,
    public transferService: TransferService,
    private authService: AuthService,
    private convsService: ConvsService,
    private domainService: DomainService,
    private keyClickService: KeyClickService,
    private sidebarService: SidebarService,
    private signoutService: SignoutService
  ) {}

  ngOnDestroy() {
    this.stopKeyClickListeners();
  }

  ngOnInit() {
    this.handleKeyClickDown = this.handleKeyClickDown.bind(this);
    this.handleKeyClickUp = this.handleKeyClickUp.bind(this);

    this.startKeyClickListeners();
  }

  onLogout() {
    if (this.convsService.hasDraftsRemaining()) {
      return this.swal.fire().then(response => {
        if (response.isConfirmed) {
          this.signoutService.signout();
        }
      });
    } else {
      this.signoutService.signout();
    }
  }

  private handleKeyClickDown(event) {
    this.keyClickService.handleKeyClick(event);
  }

  private handleKeyClickUp(event) {
    this.keyClickService.handleKeyClickUp(event);
  }

  private startKeyClickListeners() {
    document.addEventListener('keydown', this.handleKeyClickDown);
    document.addEventListener('keyup', this.handleKeyClickUp);
  }

  private stopKeyClickListeners() {
    document.removeEventListener('keydown', this.handleKeyClickDown);
    document.removeEventListener('keyup', this.handleKeyClickUp);
  }
}
