import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { Domain } from '../models/domain';
import { DomainService } from '../services/domain.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  error = false;
  form: FormGroup;
  foundDomain = false;
  loading = true;
  submitting = false;
  version: string = environment.version;

  constructor(
    private authService: AuthService,
    private domainService: DomainService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.loading = false;
    this.checkForDomainData().subscribe(() => {
      console.log('Done with domain check');
      this.loading = false;
    });
    this.buildForm();
  }

  onSubmit() {
    this.error = false;
    this.submitting = true;
    const { accountId } = this.form.value;
    this.checkForDomainData(
      this.domainService.getAccountDomainString(accountId)
    ).subscribe(domain => {
      if (!domain) {
        this.error = true;
      }
      this.submitting = false;
    });
  }

  private buildForm() {
    this.form = this.fb.group({
      accountId: [null, Validators.required]
    });
  }

  private checkForDomainData(domainStr: string = null): Observable<Domain> {
    if (!domainStr) {
      domainStr = this.domainService.getDomainUrl();
    }
    return this.domainService.fetchDomainData(domainStr).pipe(
      map(domain => {
        console.log('Got domain doc', domain);
        this.foundDomain = true;
        this.goToNextPage(domain.aid);
        return domain;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      first()
    );
  }

  private goToNextPage(aid) {
    if (this.route.snapshot.queryParams.redirect) {
      console.log(`Navigating to ${this.route.snapshot.queryParams.redirect}`);
      this.router.navigateByUrl(this.route.snapshot.queryParams.redirect);
    } else if (this.route.snapshot.queryParams.legacyRedirect) {
      console.log(
        `Navigating to ${this.route.snapshot.queryParams.legacyRedirect}`
      );
      const legacyUrl = decodeURIComponent(
        this.route.snapshot.queryParams.legacyRedirect
      ).split('/');
      this.router.navigate(['accounts', aid].concat(legacyUrl));
    } else if (this.authService.isAuthed) {
      console.log('Navigating to base account default job', aid);
      this.router.navigate(['accounts', aid]);
    } else {
      console.log('Navigating to base account login page', aid);
      this.router.navigate(['accounts', aid, 'login']);
    }
  }
}
