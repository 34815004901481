import {
  NgbCarouselModule,
  NgbDropdownModule,
  NgbNavModule
} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';

import { ActivityBadgeComponent } from '../activity-badge/activity-badge.component';
import { CommonModule } from '@angular/common';
import { ConvActionsComponent } from '../conv-actions/conv-actions.component';
import { ConvActionsFooterComponent } from '../conv-actions-footer/conv-actions-footer.component';
import { ConvActionsFooterDefaultComponent } from '../conv-actions-footer-default/conv-actions-footer-default.component';
import { ConvBodyComponent } from '../conv-body/conv-body.component';
import { ConvComponent } from '../conv/conv.component';
import { ConvHeaderComponent } from '../conv-header/conv-header.component';
import { ConvSidebarComponent } from '../conv-sidebar/conv-sidebar.component';
import { ConvSidebarLeadInfoComponent } from '../conv-sidebar-lead-info/conv-sidebar-lead-info.component';
import { ConvSidebarQuestionsComponent } from '../conv-sidebar-questions/conv-sidebar-questions.component';
import { ConvSidebarTemplatesComponent } from '../conv-sidebar-templates/conv-sidebar-templates.component';
import { CurrentJobGuard } from '../guards/current-job.guard';
import { DateProxyPipe } from '../pipes/date-proxy.pipe';
import { DefaultJobGuard } from '../guards/default-job.guard';
import { EmojiPickerComponent } from '../emoji-picker/emoji-picker.component';
import { EventActionMenuComponent } from '../event-action-menu/event-action-menu.component';
import { HomePageAnnouncementsComponent } from '../home-page-announcements/home-page-announcements.component';
import { HomePageComponent } from '../home-page/home-page.component';
import { HomePageDefaultComponent } from '../home-page-default/home-page-default.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { JobsGuard } from '../guards/jobs.guard';
import { MainComponent } from './main.component';
import { MediaPreviewComponent } from '../media-preview/media-preview.component';
import { NgModule } from '@angular/core';
import { PendingAgentPageComponent } from '../pending-agent-page/pending-agent-page.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { SidebarConvListComponent } from '../sidebar-conv-list/sidebar-conv-list.component';
import { SidebarEmptyImageComponent } from '../sidebar-empty-image/sidebar-empty-image.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TemplateService } from '../services/template.service';
import { TranslateModule } from '@ngx-translate/core';
import { UserMenuComponent } from '../user-menu/user-menu.component';

const routes: Routes = [
  {
    path: 'jobs',
    component: MainComponent,
    canActivate: [JobsGuard],
    children: [
      {
        path: 'default',
        pathMatch: 'full',
        canActivate: [DefaultJobGuard]
      },
      {
        path: ':jobid',
        canActivateChild: [CurrentJobGuard],
        children: [
          {
            path: 'conv/:convid',
            component: ConvComponent
          },
          {
            path: '',
            pathMatch: 'full',
            component: HomePageComponent
          }
        ]
      },
      { path: '', pathMatch: 'full', redirectTo: 'default' }
    ]
  },
  { path: 'pending', component: PendingAgentPageComponent },
  { path: '', pathMatch: 'full', redirectTo: 'jobs/default' }
];

@NgModule({
  declarations: [
    MainComponent,
    PendingAgentPageComponent,
    SidebarComponent,
    HomePageComponent,
    ConvComponent,
    ConvBodyComponent,
    ConvActionsFooterComponent,
    ConvActionsFooterDefaultComponent,
    SidebarConvListComponent,
    SidebarEmptyImageComponent,
    ConvSidebarComponent,
    ConvSidebarLeadInfoComponent,
    ConvSidebarQuestionsComponent,
    ConvSidebarTemplatesComponent,
    ConvActionsComponent,
    ActivityBadgeComponent,
    ConvHeaderComponent,
    EmojiPickerComponent,
    EventActionMenuComponent,
    HomePageDefaultComponent,
    HomePageAnnouncementsComponent,
    MediaPreviewComponent,
    UserMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgbCarouselModule,
    NgbDropdownModule,
    NgbNavModule,
    PickerModule,
    SharedModule,
    TranslateModule.forChild({ extend: true }),
    SweetAlert2Module
  ],
  providers: [
    CurrentJobGuard,
    DefaultJobGuard,
    JobsGuard,
    TemplateService,
    DateProxyPipe
  ]
})
export class MainModule {}
