import { Component, Input, OnInit } from '@angular/core';

import { Lead } from '../models/leads';
import { isSmallScreenHeight } from '../utils/screens';

@Component({
  selector: 'app-conv-sidebar-lead-info',
  templateUrl: './conv-sidebar-lead-info.component.html',
  styleUrls: ['./conv-sidebar-lead-info.component.scss']
})
export class ConvSidebarLeadInfoComponent implements OnInit {
  @Input() data: { lead: Lead; auxData: string[] };

  isExpanded = !isSmallScreenHeight();

  constructor() {}

  ngOnInit() {}
}
