<ng-container *ngIf="data$ | async as data; else loading">
  <div class="header d-flex d-xl-none">
    <button
      class="back-btn btn btn-link d-flex d-lg-none align-items-center"
      (click)="onBack()"
    >
      <svg
        class="back-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512"
      >
        <path
          d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
        />
      </svg>
      <span class="ml-1">Back</span>
    </button>
  </div>

  <ng-container *ngIf="data.announcements.length; else noAnnouncements">
    <app-home-page-announcements
      [announcements]="data.announcements"
      (newConvo)="createNewConv()"
      (read)="clearAnnouncements()"
    >
    </app-home-page-announcements>
  </ng-container>

  <ng-template #noAnnouncements>
    <app-home-page-default
      [agent]="data.agent"
      (newConvo)="createNewConv()"
    ></app-home-page-default>
  </ng-template>
</ng-container>

<ng-template #loading>
  <div
    class="d-flex flex-column align-items-center justify-content-center h-100"
  >
    <app-spinner width="4rem" class="spinner"></app-spinner>
  </div>
</ng-template>
