<div class="d-flex h-100 justify-content-center align-items-center">
  <div class="cta d-flex flex-column align-items-center p-3">
    <ng-container *ngIf="!showHints">
      <h2 class="text-center">{{ 'reload.hangOn' | translate }}</h2>
      <img
        src="assets/svgs/broken.svg"
        [alt]="'reload.upgradeImg' | translate"
      />
      <p class="mt-3">
        {{ 'reload.newVersion' | translate }}
      </p>
      <h4>{{ 'reload.currentVersion' | translate }}: {{ currentUI }}</h4>
      <h4 class="font-weight-bold">
        {{ 'reload.requiredVersion' | translate }}: {{ minimumUI }}
      </h4>
      <button class="btn btn-primary mt-3" (click)="reloadUi()">
        {{ 'reload.clickHere' | translate }}
      </button>
      <a class="text-primary mt-3" (click)="showHints = !showHints">{{
        'reload.showHints' | translate
      }}</a>
    </ng-container>
    <ng-container *ngIf="showHints">
      <h4>{{ 'reload.onComputer' | translate }}</h4>
      <p>{{ 'reload.ctrlF5' | translate }}</p>
      <h4 class="mt-3">{{ 'reload.onMobile' | translate }}</h4>
      <p>{{ 'reload.closeTab' | translate }}</p>
      <a class="text-primary mt-3" (click)="showHints = !showHints">{{
        'reload.goBack' | translate
      }}</a>
    </ng-container>
  </div>
</div>
