import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  CompletedEvent,
  Event,
  EventCssClass,
  EventState,
  MMSEvent,
  OptOutEvent,
  QuestionResponseEvent,
  ReopenEvent,
  TextEvent,
  TransferEvent
} from '../models/event';
import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger
} from '@angular/animations';

import { EventMenuService } from '../services/event-menu.service';
import { MediaPreviewComponent } from '../media-preview/media-preview.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-conv-body',
  templateUrl: './conv-body.component.html',
  styleUrls: ['./conv-body.component.scss'],
  animations: [
    trigger('pageAnimations', [
      transition(':enter', [
        query(
          '.message',
          [
            style({ opacity: 0, transform: 'translateY(-30px)' }),
            stagger(-80, [
              animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style('*'))
            ])
          ],
          { optional: true }
        )
      ])
    ])
  ]
})
export class ConvBodyComponent implements OnInit, AfterViewChecked {
  hasScrolled = false;

  @Output() event = new EventEmitter<
    CompletedEvent | OptOutEvent | ReopenEvent
  >();

  @HostBinding('@pageAnimations')
  @Input()
  events: (TextEvent | TransferEvent)[] = [];
  @HostListener('scroll', ['$event'])
  private onScroll($event: Event): void {
    this.hasScrolled = true;
  }

  constructor(
    private element: ElementRef,
    private eventMenuService: EventMenuService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  onActionClick(e: Event, event: MouseEvent) {
    if (e.cssClass === EventCssClass.lead) {
      this.openActionMenu(e, event.clientX, event.clientY);
    }
  }

  previewMedia(event: MMSEvent) {
    const modalRef = this.modalService.open(MediaPreviewComponent, {
      centered: true,
      windowClass: 'media-preview-modal'
    });
    this.setModalPreviewValues(modalRef.componentInstance, event);
  }

  reset() {
    this.scrollToBottom();
    this.hasScrolled = false;
  }

  scrollToBottom() {
    const isScrolledToBottom =
      this.element.nativeElement.scrollHeight -
        this.element.nativeElement.clientHeight <=
      this.element.nativeElement.scrollTop + 1;

    if (!isScrolledToBottom && !this.hasScrolled) {
      this.element.nativeElement.scrollTop =
        this.element.nativeElement.scrollHeight -
        this.element.nativeElement.clientHeight;
    } else if (isScrolledToBottom && this.hasScrolled) {
      this.hasScrolled = false;
    }
  }

  private setModalPreviewValues(comp, event: MMSEvent) {
    comp.type = event.media_type ? event.media_type : 'IMAGE';
    comp.url = event.media_id ? event.preview_url : event.media;
    comp.title = event.title;
  }

  showError(
    event: TextEvent | TransferEvent | CompletedEvent | QuestionResponseEvent
  ) {
    if (
      event.state !== EventState.delivered &&
      event.state !== EventState.pending &&
      event.state !== EventState.unread &&
      event.state !== EventState.read &&
      event.state !== EventState.completed
    ) {
      return true;
    }
    return false;
  }

  openActionMenu(event: Event, x: number, y: number) {
    this.eventMenuService.openEventMenu(event, x, y, this.event);
  }
}
