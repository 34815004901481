export function isSmallScreenHeight(): boolean {
  return window.innerHeight < 1024;
}

export function isSmallScreenWidth(): boolean {
  return window.innerWidth < 992;
}

export function isMediumScreenWidth(): boolean {
  return window.innerWidth < 1200;
}

export function isMobile(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
    navigator.userAgent
  );
}
