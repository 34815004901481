import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  isSpinning = false;
  spinnerText: string;

  constructor() {}

  startSpinner(text: string) {
    if (this.isSpinning) {
      return;
    }

    this.isSpinning = true;
    this.spinnerText = text;
  }

  stopSpinner() {
    this.spinnerText = null;
    this.isSpinning = false;
  }
}
