import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../services/auth.service';
import { Domain } from '../models/domain';
import { DomainService } from '../services/domain.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  error: string;
  domain$ = this.domainService.domain$;
  form: FormGroup;
  submitting = false;

  constructor(
    private auth: AuthService,
    private domainService: DomainService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      first_name: [null, Validators.required],
      last_name: [null],
      email: [null, Validators.required],
      password: [null, Validators.required],
      password2: [null, Validators.required]
    });
  }

  ngOnInit() {
    console.log(this.submitting);
  }

  onSubmit(accountId: string) {
    console.table(this.form.value);
    const {
      email,
      password,
      password2,
      first_name,
      last_name
    } = this.form.value;

    if (password !== password2) {
      this.error = 'Passwords do not match';
      return;
    }

    this.submitting = true;
    this.auth
      .signUpWithEmailAndPassword(
        accountId,
        email,
        password,
        first_name,
        last_name
      )
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
      .finally(() => (this.submitting = false));
  }
}
