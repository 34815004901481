import * as firebase from 'firebase/app';

import {
  AngularFirestore,
  DocumentChangeAction,
  QueryDocumentSnapshot
} from '@angular/fire/firestore';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  from,
  interval,
  of,
  timer
} from 'rxjs';
import {
  CompletedEvent,
  DeferredEvent,
  Event,
  EventCssClass,
  EventState,
  EventType,
  MMSEvent,
  OptOutEvent,
  QuestionResponseEvent,
  ReopenEvent,
  TextEvent,
  TransferEvent
} from '../models/event';
import {
  Conversation,
  ConversationRequest,
  QuestionBase,
  SelectQuestion
} from '../models/conversation';
import { Job, JobDisposition, JobSelectQuestionResponse } from '../models/job';
import {
  catchError,
  concatMap,
  delay,
  delayWhen,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeAll,
  retryWhen,
  scan,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  tap,
  windowTime
} from 'rxjs/operators';

import { Agent } from '../models/agent';
import { AlertsService } from './alerts.service';
import { AuthService } from './auth.service';
import { DomainService } from './domain.service';
import { Injectable } from '@angular/core';
import { JobsService } from './jobs.service';
import { LanguageService } from './language.service';
import { Lead } from '../models/leads';
import { LeadsService } from './leads.service';
import { ResponderService } from './responder.service';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import { StatsService } from './stats.service';
import { TimeService } from './time.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConvsService {
  CONVERSATION_LIST_BATCH_SIZE = 100;

  actionableConversations = new BehaviorSubject<Conversation[]>([]);
  actionableConversations$ = this.actionableConversations.asObservable();
  private activePendingConversationsConnection = new BehaviorSubject<boolean>(
    false
  );
  activeTransmitRequests = new BehaviorSubject<number>(0);
  allConversations = new BehaviorSubject<Conversation[]>([]);
  allConversations$ = this.allConversations.asObservable();
  errorConversations = new BehaviorSubject<Conversation[]>([]);
  errorConversations$ = this.errorConversations.asObservable();
  stopConversations = new BehaviorSubject<Conversation[]>([]);
  stopConversations$ = this.stopConversations.asObservable();
  private canSendMessages = new BehaviorSubject<boolean>(true);
  canSendMessages$ = this.canSendMessages.asObservable();
  clearTemplate$ = new BehaviorSubject<string>(null);
  private connectionErrorAlertId: string;
  currentConversation = new BehaviorSubject<Conversation>(null);
  currentConversation$ = this.currentConversation.asObservable();
  disabledByConfig = false;
  fetchingActionableDocs = false;
  fetchingAllDocs = false;
  filterDraftMessages = new BehaviorSubject(false);
  filterErrorMessages = new BehaviorSubject(true);
  finishedActionableDocs = false;
  finishedAllDocs = false;
  finishedErrorDocs = false;
  finishedStopDocs = false;
  hasInitialized = false;
  hasMinimumUi = true;
  isRoutingToNewConv = new BehaviorSubject<boolean>(false);
  lastFetchedActionableDoc: QueryDocumentSnapshot<Conversation> = null;
  lastFetchedAllDoc: QueryDocumentSnapshot<Conversation> = null;
  localTimeInterval: NodeJS.Timeout;
  makingConvRequest = new BehaviorSubject<boolean>(false);
  pendingConversations$ = new BehaviorSubject<Conversation[]>([]);
  pendingFilters: string[] = [];
  loadNextPending = false;
  sending = false;
  ready = new BehaviorSubject<boolean>(true);
  readyInSubject = new Subject<number>();
  readyInterval = new BehaviorSubject<number>(300);
  isFastModeEnabled = new BehaviorSubject<boolean>(false);
  inboxSize = new BehaviorSubject<number>(this.CONVERSATION_LIST_BATCH_SIZE);
  inboxSizeRequest = new Subject<number>();
  allSize = new BehaviorSubject<number>(this.CONVERSATION_LIST_BATCH_SIZE);
  allSizeRequest = new Subject<number>();
  errorSize = new BehaviorSubject<number>(this.CONVERSATION_LIST_BATCH_SIZE);
  errorSizeRequest = new Subject<number>();
  stopSize = new BehaviorSubject<number>(this.CONVERSATION_LIST_BATCH_SIZE);
  stopSizeRequest = new Subject<number>();
  requestConversations = new Subject<number>();
  mainSub: Subscription;
  subs: Subscription[] = [];
  showingTransmitErrorMessage = false;
  transmitRequestErrorCount = new BehaviorSubject<number>(0);
  transmitErrorIds: string[] = [];
  transmitRequestErrorTimer: NodeJS.Timeout;
  sendSignalCount = 0;
  sendSignalTimer: NodeJS.Timeout;

  requestConversationsCloudFunction = firebase
    .functions()
    .httpsCallable('conversations-requestConversations');

  addConversationEventCloudFunction = firebase
    .functions()
    .httpsCallable('conversations-addConversationEvent');

  updateOpenWithResponses = firebase
    .functions()
    .httpsCallable('conversations-updateOpenWithResponses');

  subscribeToAllConversations = this.getConversationListSubscription(
    'all'
  ).pipe(
    catchError((err, caught) => {
      if (err.code === 'permission-denied') {
        return of([]);
      }
    }),
    map(convs => this.updateAllConversationList(convs))
  );

  subscribeToErrorConversations = this.getConversationListSubscription(
    'error'
  ).pipe(
    catchError((err, caught) => {
      if (err.code === 'permission-denied') {
        return of([]);
      }
    }),
    map(convs => this.updateErrorConversationList(convs))
  );

  subscribeToStopConversations = this.getConversationListSubscription(
    'stop'
  ).pipe(
    catchError((err, caught) => {
      if (err.code === 'permission-denied') {
        return of([]);
      }
    }),
    map(convs => this.updateStopConversationList(convs))
  );

  constructor(
    private afs: AngularFirestore,
    private alertsService: AlertsService,
    private authService: AuthService,
    private domainService: DomainService,
    private jobsService: JobsService,
    private languageService: LanguageService,
    private leadsService: LeadsService,
    private responderService: ResponderService,
    private router: Router,
    private spinnerService: SpinnerService,
    private statsService: StatsService,
    private timeService: TimeService
  ) {
    // console.log('Init convsService');
    let currentAgent: Agent = null;
    this.authService.agent$
      .pipe(map(agent => agent as Agent))
      .subscribe((agent: Agent) => {
        if (agent === null) {
          if (this.mainSub) {
            this.mainSub.unsubscribe();
          }
          currentAgent = null;
          return;
        } else if (currentAgent && currentAgent.id === agent.id) {
          return;
        }

        currentAgent = agent;
        this.initData(agent);
      });

    this.incrementSendSignalCount = this.incrementSendSignalCount.bind(this);
    this.resetSendSignalCount = this.resetSendSignalCount.bind(this);
    this.subscribeToTransmitErrors = this.subscribeToTransmitErrors.bind(this);

    this.subscribeToReady();
    this.subscribeToLoadingPendingConversations();
    this.subscribeToListSizeRequests();
    this.subscribeToSystemConfigs();
    this.subscribeToTransmitErrors();
    this.subscribeToCanSendMessages();
  }

  initData(agent) {
    this.hasInitialized = true;

    this.subscribeToActionableConversations();
    this.subscribeToPendingConversations();
    this.subscribeToConversationRequests();
  }

  canSendMessage(): boolean {
    return this.canSendMessages.value;
  }

  subscribeToSystemConfigs() {
    this.domainService.systemConfigs.subscribe(config => {
      // console.log('Setting ready interval', config.ready_interval);
      this.readyInterval.next(config.ready_interval);
      this.disabledByConfig = config.disabled || false;
      this.hasMinimumUi = config.has_minimum_ui || true;
    });
  }

  subscribeToTransmitErrors() {
    combineLatest([
      this.activeTransmitRequests,
      this.transmitRequestErrorCount
    ]).subscribe(([activeRequests, errorCount]) => {
      if (!this.showingTransmitErrorMessage && errorCount > 9) {
        this.showingTransmitErrorMessage = true;
        this.transmitErrorIds.push(
          this.alertsService.setMessage('alert.serviceUnavailable')
        );
        this.ready.next(false);
      } else if (this.showingTransmitErrorMessage && activeRequests === 0) {
        this.showingTransmitErrorMessage = false;

        for (const id of this.transmitErrorIds) {
          this.alertsService.clearMessage(id);
        }

        this.transmitErrorIds = [];
        this.isFastModeEnabled.next(false);
        this.ready.next(true);
        this.transmitRequestErrorCount.next(0);
      }
    });
  }

  subscribeToListSizeRequests() {
    this.inboxSizeRequest
      .pipe(
        windowTime(500),
        mergeAll(),
        tap(val => console.log(val)),
        filter(Boolean),
        scan(
          (acc, _) => acc + this.CONVERSATION_LIST_BATCH_SIZE,
          this.CONVERSATION_LIST_BATCH_SIZE
        )
      )
      .subscribe(req => {
        console.log('next inbox size', req);
        this.inboxSize.next(req);
      });

    this.allSizeRequest
      .pipe(
        windowTime(500),
        mergeAll(),
        tap(val => console.log(val)),
        filter(Boolean),
        scan(
          (acc, _) => acc + this.CONVERSATION_LIST_BATCH_SIZE,
          this.CONVERSATION_LIST_BATCH_SIZE
        )
      )
      .subscribe(req => {
        console.log('next all size', req);
        this.allSize.next(req);
      });

    this.errorSizeRequest
      .pipe(
        windowTime(500),
        mergeAll(),
        tap(val => console.log(val)),
        filter(Boolean),
        scan(
          (acc, _) => acc + this.CONVERSATION_LIST_BATCH_SIZE,
          this.CONVERSATION_LIST_BATCH_SIZE
        )
      )
      .subscribe(req => {
        console.log('next error size', req);
        this.errorSize.next(req);
      });

    this.stopSizeRequest
      .pipe(
        windowTime(500),
        mergeAll(),
        tap(val => console.log(val)),
        filter(Boolean),
        scan(
          (acc, _) => acc + this.CONVERSATION_LIST_BATCH_SIZE,
          this.CONVERSATION_LIST_BATCH_SIZE
        )
      )
      .subscribe(req => {
        console.log('next stop size', req);
        this.stopSize.next(req);
      });
  }

  subscribeToReady() {
    this.readyInSubject
      .pipe(
        tap(() => {
          console.log('setting ready to false');
          this.ready.next(false);
        }),
        concatMap(timetodelay => of(null).pipe(delay(timetodelay)))
      )
      .subscribe(() => {
        console.log('setting ready to true');
        this.ready.next(true);
      });
  }

  subscribeToCanSendMessages() {
    combineLatest([
      this.ready,
      this.activeTransmitRequests,
      this.domainService.systemConfigs,
      this.isRoutingToNewConv,
      this.activePendingConversationsConnection
    ]).subscribe(
      ([
        ready,
        activeTransmitRequests,
        systemConfigs,
        isRoutingToNewConv,
        activePendingConversationsConnection
      ]) => {
        this.canSendMessages.next(
          ready &&
            !systemConfigs.disabled &&
            systemConfigs.has_minimum_ui &&
            activeTransmitRequests < 10 &&
            !isRoutingToNewConv &&
            activePendingConversationsConnection
        );
      }
    );
  }

  startFastMode() {
    this.isFastModeEnabled.next(true);
    this.statsService.isFastModeEnabled = true;
  }

  stopFastMode() {
    console.log('Stopping isFastModeEnabled');
    this.isFastModeEnabled.next(false);
    this.statsService.isFastModeEnabled = false;
  }

  subscribeToLoadingPendingConversations() {
    this.isFastModeEnabled
      .pipe(
        distinctUntilChanged(),
        filter(val => val === true),
        switchMapTo(
          interval(100).pipe(
            takeUntil(
              this.isFastModeEnabled.pipe(
                distinctUntilChanged(),
                filter(val => val === false)
              )
            )
          )
        ),
        switchMapTo(
          this.pendingConversations$.pipe(
            take(1),
            map(c => c.length)
          )
        ),
        filter(len => len < 9),
        tap(len => {
          if (len === 0 && this.makingConvRequest.value) {
            this.spinnerService.startSpinner('spinner.loadingConversations');
          }
        }),
        filter(() => !this.makingConvRequest.value)
      )
      .subscribe(() => {
        console.log('asking for 10 more leads');
        this.requestConversations.next(10);
      });

    this.pendingConversations$.subscribe(pendingConvs => {
      if (pendingConvs.length > 0) {
        this.spinnerService.stopSpinner();
        if (this.loadNextPending) {
          console.log(
            'loadNextPending was set to True. Loading next pending conv.'
          );
          this.router.navigate([
            'accounts',
            this.authService.agent.value.aid,
            'jobs',
            this.jobsService.currentJob.value.id,
            'conv',
            pendingConvs[0].id
          ]);
          this.loadNextPending = false;
        }
      }
    });
  }

  subscribeToActionableConversations() {
    const sub = this.getConversationListSubscription('inbox')
      .pipe(
        distinctUntilChanged(),
        catchError((err, caught) => {
          if (err.code === 'permission-denied') {
            return of([]);
          }
        })
      )
      .subscribe((actionable: Conversation[]) => {
        this.updateActionableConversationList(actionable);
      });
    this.subs.push(sub);
  }

  subscribeToPendingConversations() {
    const sub = this.getConversationListSubscription('pending')
      .pipe(
        distinctUntilChanged(),
        catchError((err, caught) => {
          if (err.code === 'permission-denied') {
            return of([]);
          }
        })
      )
      .subscribe((pending: Conversation[]) => {
        this.updatePendingConversationList(pending);
      });
    this.subs.push(sub);
  }

  subscribeToConversationRequests() {
    const $agentSub = this.authService.agent.pipe(
      filter(Boolean),
      tap((agent: Agent) => console.log('ConvReq: new agent', agent))
    );
    const $jobSub = this.jobsService.currentJob.pipe(
      filter(Boolean),
      map((job: Job) => job.id),
      distinctUntilChanged(),
      tap(jobId => console.log('ConvReq: new job', jobId))
    );

    let startRequestTime: number;
    let endRequestTime: number;

    const sub = combineLatest([$agentSub, $jobSub])
      .pipe(
        switchMap(([agent, jid]) =>
          this.requestConversations.pipe(
            filter(cnt => !this.makingConvRequest.value),
            switchMap(cnt => {
              this.makingConvRequest.next(true);
              // this.alertsService.clearMessage();
              this.spinnerService.startSpinner('spinner.loadingConversations');
              const id = this.afs.createId();
              const request = {
                id,
                jobId: jid,
                agent,
                state: 'new',
                count: cnt,
                ui_version: environment.version,
                ui_language: this.languageService.getCurrentLanguage()
              } as ConversationRequest;

              if (agent.phone) {
                request.phone = agent.phone;
              }

              console.log(
                `ConvReq: ${id} -- ${agent.display_name} requested for ${cnt} convs for job ${jid}`
              );
              startRequestTime = this.timeService.now.getTime();
              return from(
                this.requestConversationsCloudFunction({ request })
                  .then(result => {
                    console.log('Successful onCall conversation request', {
                      response: result.data
                    });
                    return result.data as ConversationRequest;
                  })
                  .catch(err => {
                    console.error(
                      'requestConversationsCloudFunction failed: ',
                      err
                    );
                    throw err;
                  })
              ).pipe(take(1));
            })
          )
        )
      )
      .subscribe(
        (req: ConversationRequest) => {
          endRequestTime = this.timeService.now.getTime();
          console.log(
            `ConvReq took ${(endRequestTime - startRequestTime) / 1000}s`
          );
          this.spinnerService.stopSpinner();
          if (req.state === 'created') {
            this.makingConvRequest.next(false);
            console.log('ConvReq:', req.id, 'done');
            return;
          }

          // 403 errors are leads and account errors.
          // 429 errors are out of unlimited hour tokens.
          // 420 errors are out of unlimited daily credits.
          // We are adding a delay here so the agent hopefully doesn't continue
          // to spam Rogue Leader when they can't do anything.
          if ([403, 420, 429].includes(req.errno)) {
            this.readyInSubject.next(3000);
            setTimeout(() => {
              this.makingConvRequest.next(false);
            }, 3000);

            if (this.pendingConversations$.value.length === 0) {
              this.stopFastMode();
            }
          } else {
            this.makingConvRequest.next(false);
          }

          console.log(
            'ConvReq: ',
            req.id,
            `New conversation failed to be created: (${req.errno}) ${req.errmsg}`
          );
          if (req.errno === 429) {
            this.alertsService.setMessage(
              'alert.unlimitedLimit',
              undefined,
              'info'
            );
          } else if (req.errno === 420) {
            this.alertsService.setMessage(
              'alert.unlimitedDailyLimit',
              undefined,
              'info'
            );
          } else {
            this.alertsService.setMessage('alert.failedNewConversation', {
              errno: req.errno || 500,
              errmsg: req.errmsg || 'Internal Error'
            });
          }
        },
        err => {
          if (err === 'timeout') {
            console.error(
              'ConvReq: ERROR - Failed due to a timeout requesting new conversations.'
            );
            this.alertsService.setMessage('alert.timeout');
          } else {
            console.error(
              'ConvReq: ERROR - Failed due to unknown reason.',
              err
            );
            this.alertsService.setMessage('alert.unknownError', { error: err });
          }
          this.makingConvRequest.next(false);
          this.spinnerService.stopSpinner();
        }
      );
    this.subs.push(sub);
  }

  clearActiveTemplate(template: string) {
    this.clearTemplate$.next(template);
  }

  getConversationListSubscription(
    convType: 'pending' | 'inbox' | 'all' | 'stop' | 'error' | 'draft'
  ) {
    let listSizeSub = this.allSize;

    if (convType === 'inbox') {
      listSizeSub = this.inboxSize;
    } else if (
      convType === 'stop' ||
      convType === 'error' ||
      convType === 'draft'
    ) {
      listSizeSub = this.stopSize;
    }

    return this.jobsService.currentJob.pipe(
      filter(Boolean),
      map(job => job as Job),
      distinctUntilKeyChanged('id'),
      switchMap(job => {
        if (convType === 'inbox') {
          return combineLatest([listSizeSub, this.filterErrorMessages]).pipe(
            map(([size, filterErrorMessages]) => ({
              job,
              size,
              filterErrorMessages
            }))
          );
        }
        return listSizeSub.pipe(map(size => ({ job, size })));
      }),
      switchMap(
        (res: { job: Job; size: number; filterErrorMessages?: boolean }) =>
          this.afs
            .collection<Conversation>('conversations', ref => {
              let query = ref
                .where('activeAgent.uida', '==', this.authService.uida)
                // NOTE: This assumes agents live in base_account and not account directly
                .where('activeAgent.aid', '==', res.job.base_account_id)
                .where(
                  'jobId',
                  '==',
                  this.afs.doc<Job>(`jobs/${res.job.id}`).ref
                );

              if (convType === 'pending') {
                query = query
                  .where('state', 'in', [
                    EventCssClass.new,
                    EventCssClass.rehash
                  ])
                  .orderBy('lastEventDate', 'asc')
                  .limit(200);
                return query;
              } else if (convType === 'inbox') {
                const inboxStates = [
                  EventCssClass.lead,
                  EventCssClass.info,
                  EventCssClass.success,
                  EventCssClass.warning
                ];

                if (!res.filterErrorMessages) {
                  inboxStates.push(EventCssClass.danger);
                }

                query = query
                  .where('state', 'in', inboxStates)
                  .orderBy('lastEventDate', 'desc');
              } else if (convType === 'stop') {
                query = query
                  .where('state', 'in', [EventCssClass.stop])
                  .orderBy('lastEventDate', 'desc');
              } else if (convType === 'error') {
                query = query
                  .where('state', 'in', [EventCssClass.danger])
                  .orderBy('lastEventDate', 'desc');
              } else if (convType === 'all') {
                query = query
                  .where('state', 'in', [
                    EventCssClass.lead,
                    EventCssClass.danger,
                    EventCssClass.info,
                    EventCssClass.success,
                    EventCssClass.warning,
                    EventCssClass.agent,
                    EventCssClass.completed,
                    EventCssClass.stop
                  ])
                  .orderBy('lastEventDate', 'desc');
              }

              return query.limit(res.size);
            })
            .snapshotChanges()
            .pipe(map(this.unpackConversationData))
      ),
      // If the connection is lost to an actively subscribed inbox, we want to try to self
      // heal the connection to firestore. This retryWhen block will attempt to reconnect the
      // observable subscription with a backoff of 10s times the number of retry attempts.
      retryWhen(err => {
        console.error(
          `Failed fetching conversations from Firestore for convType ${convType}: `,
          err
        );

        return err.pipe(
          scan((acc, error) => {
            return acc + 1;
          }, 0),
          map(retryCount => {
            const retryDelay = retryCount * 10 * 1000;

            if (convType === 'pending') {
              // Disable the UI from sending/requesting messages until the pending
              // conversations list connection is restored.
              // Also show an error since we are disabling the UI.
              this.setActivePendingConversationsConnection(false);
              if (!this.connectionErrorAlertId) {
                this.connectionErrorAlertId = this.alertsService.setMessage(
                  `Connection error. Attempting to reconnect...`
                );
              }
            }

            return retryDelay;
          }),
          delayWhen(value => timer(value))
        );
      }),
      // Cleanup error messages and connection status stuff if they are set, since
      // we have an active connection again at this point if we did not before.
      tap(() => {
        if (convType === 'pending') {
          this.setActivePendingConversationsConnection(true);
          if (this.connectionErrorAlertId) {
            this.alertsService.clearMessage(this.connectionErrorAlertId);
            delete this.connectionErrorAlertId;
            this.alertsService.setMessage(
              'Connection restored',
              null,
              'success',
              4000
            );
          }
        }
      })
    );
  }

  processConvEvent(
    event:
      | Event
      | CompletedEvent
      | MMSEvent
      | TextEvent
      | TransferEvent
      | QuestionResponseEvent
      | OptOutEvent
      | DeferredEvent
      | ReopenEvent,
    convId: string
  ): Promise<Event | TextEvent | TransferEvent> {
    if (
      (event.messageType === EventType.textEvent ||
        event.messageType === EventType.mmsEvent) &&
      (event as TextEvent | MMSEvent).thenStartNew
    ) {
      this.incrementSendSignalCount();
    }

    event.id = this.afs.createId();

    let trackedLinks;
    if (
      event.messageType === EventType.textEvent ||
      event.messageType === EventType.mmsEvent ||
      event.messageType === EventType.deferredEvent
    ) {
      this.addPendingFilter(convId);
      this.readyInSubject.next(this.readyInterval.value);
      trackedLinks = this.jobsService.getCurrentJob().tracked_links;
    }

    let responderAgent = null;
    if (this.isNonResponderEvent(event.messageType)) {
      // Route away before the transaction since the logged in non-responder agent
      // will lose permissions on the convo once the transaction processes.
      responderAgent = this.responderService.getNextResponder();
      this.routeToNextConversationOrWelcome();
    }

    console.log('Adding active transmit request');
    this.activeTransmitRequests.next(this.activeTransmitRequests.value + 1);

    if (event.messageType === EventType.deferredEvent) {
      this.routeToNextConversationOrWelcome();
      this.alertsService.setMessage(
        `alert.deferred`,
        { number: convId },
        'info',
        8000
      );
    }

    let jobIntegrations;
    if (
      event.messageType === EventType.completedEvent ||
      event.messageType === EventType.optOutEvent ||
      event.messageType === EventType.reopenEvent
    ) {
      jobIntegrations = this.jobsService.getCurrentJob().integrations;
    }

    const identityId = this.jobsService.getCurrentJob().account_id;

    return this.addConversationEventCloudFunction({
      convId,
      event,
      responderAgent,
      jobIntegrations,
      trackedLinks,
      identityId
    })
      .then(response => {
        console.log('Response from addConversationEventCloudFunction: ', {
          data: response.data
        });
        return response.data.event as Event;
      })
      .then(e => {
        console.log('Removing active transmit request');
        this.activeTransmitRequests.next(this.activeTransmitRequests.value - 1);
        this.statsService.performedAction(e.messageType);

        if (
          e.cssClass === EventCssClass.agent ||
          e.cssClass === EventCssClass.completed ||
          e.messageType === EventType.optOutEvent ||
          e.messageType === EventType.deferredEvent
        ) {
          console.log(`Removing conversation ${convId} from actionable list`);
          this.removeConversationFromActionableList(convId);
        }

        return e;
      })
      .catch(error => {
        console.error(
          `Failed processing event transaction for conversation ${convId}: `,
          error
        );

        this.removeFromPendingFilter(convId);

        this.transmitRequestErrorCount.next(
          this.transmitRequestErrorCount.value + 1
        );

        // Delay removing the failed attempt from the active requests count.
        // Increase the delay based on the number of recent errors.
        setTimeout(() => {
          this.activeTransmitRequests.next(
            this.activeTransmitRequests.value - 1
          );
        }, 1000 * 12 * this.transmitRequestErrorCount.value);

        // Remove recent errors from the error count after 10 sec, so we only
        // track recent errors.
        setTimeout(() => {
          this.transmitRequestErrorCount.next(
            this.transmitRequestErrorCount.value - 1
          );
        }, 10000);

        return event;
      });
  }

  addQuestionResponseEventToConv(
    event: Event | QuestionResponseEvent,
    convId: string
  ): Observable<Event | TextEvent | TransferEvent> {
    event.id = this.afs.createId();

    return from(
      this.afs
        .collection<Event>(`conversations/${convId}/events`)
        .doc(event.id)
        .set(event)
        .then(response => {
          console.log(`Added event ${event.id} to conversation ${convId}`);
          return this.updateConvLastEventContext(event, convId);
        })
        .then(resp => {
          console.log('marking action in stats');
          this.statsService.performedAction(event.messageType);
          console.log('done marking action in stats');
          return event;
        })
        .catch(err => {
          console.error(err);
          return err;
        })
    );
  }

  clearCurrentConversation() {
    this.router.navigate(['../../']);
    this.currentConversation.next(null);
  }

  fetchMoreActionableConversations() {
    console.log('asking for more inbox');
    this.inboxSizeRequest.next(this.CONVERSATION_LIST_BATCH_SIZE);
  }

  fetchMoreAllConversations() {
    console.log('asking for more all');
    this.allSizeRequest.next(this.CONVERSATION_LIST_BATCH_SIZE);
  }

  fetchMoreErrorConversations() {
    console.log('asking for more stop conversations');
    this.errorSizeRequest.next(this.CONVERSATION_LIST_BATCH_SIZE);
  }

  fetchMoreStopConversations() {
    console.log('asking for more stop conversations');
    this.stopSizeRequest.next(this.CONVERSATION_LIST_BATCH_SIZE);
  }

  getConvEvents(convId: string): Observable<Event[]> {
    return this.afs
      .collection<Event>(`conversations/${convId}/events`)
      .snapshotChanges()
      .pipe(
        // tap(actions => console.log(`${convId}: event updates`, actions)),
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Event;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        ),
        catchError((err, caught) => {
          console.warn(`Get events error`, { error: err.code, convId });
          return of([]);
        })
      );
  }

  hasDraftsRemaining(): boolean {
    return this.pendingConversations$.value?.length > 0;
  }

  removeFromPendingFilter(convId: string) {
    this.pendingFilters = this.pendingFilters.filter(id => id !== convId);
    this.updatePendingConversationList(this.pendingConversations$.value);
    console.log(`Removed from pending filter: ${convId}`);
  }

  getCurrentConversation$(id: string): Observable<Conversation> {
    if (id && id !== this.currentConversation.value?.id) {
      return this.afs
        .doc<Conversation>(`conversations/${id}`)
        .valueChanges()
        .pipe(
          switchMap(conv => {
            const c = { id, ...conv } as Conversation;
            this.mapExistingQuestionResponses(c);
            this.trackLeadLocalTime(c.lead);
            this.currentConversation.next(c);

            return this.currentConversation.asObservable();
          }),
          catchError((err, caught) => {
            if (err.code === 'permission-denied') {
              console.warn(`Permission denied getting conversation ${id}`);
              // NOTE: Turning this off currently.
              // Causing issues with some non - responder agents getting convos transferred away and throwing this error.
              // this.alertsService.setMessage(
              //   "Uh oh, you don't have permission to view that conversation."
              // );
            } else {
              console.error('Error getting conversation', {
                error: err.code,
                convId: id
              });
              this.alertsService.setMessage(
                "We can't find that conversation. Something went wrong."
              );
            }

            this.clearCurrentConversation();
            return of(null);
          })
        );
    }

    if (!id) {
      this.clearCurrentConversation();
    }
    return this.currentConversation.asObservable();
  }

  signOut() {
    if (this.subs) {
      this.subs.forEach(s => s.unsubscribe());
    }
    this.subs = [];

    this.actionableConversations.next([]);
    this.allConversations.next([]);
    this.stopConversations.next([]);
    this.clearCurrentConversation();
    this.fetchingActionableDocs = false;
    this.fetchingAllDocs = false;
    this.finishedActionableDocs = false;
    this.finishedAllDocs = false;
    this.finishedErrorDocs = false;
    this.finishedStopDocs = false;
    this.lastFetchedActionableDoc = null;
    this.lastFetchedAllDoc = null;
    this.makingConvRequest.next(false);
    this.pendingConversations$.next([]);
    this.sending = false;

    if (this.localTimeInterval) {
      clearInterval(this.localTimeInterval);
    }

    this.hasInitialized = false;
    console.log('Conversation service signed out');
  }

  startNewConv(nextOrNew: string = 'new') {
    if (nextOrNew === 'new' && this.pendingConversations$.value.length > 0) {
      // we already have the conversation doc, so we're going to set it so
      // that we can optimize out the conv resolver making a firestore call
      // to get the conversation document that we already have
      console.log(
        'Agent already has a pending conversation available for start new'
      );

      // On some slow computers routing to a new conversation was taking longer than
      // the delay interval between messages. This was causing the startNewConv
      // function to loop routing to the "next" conversation and not actually ever
      // complete the route.
      // I am adding this isRouting subject to the "ready" check so a new conversation
      // request cannot be started if there is already one in progress.
      this.isRoutingToNewConv.next(true);
      this.router
        .navigate([
          'accounts',
          this.authService.agent.value.aid,
          'jobs',
          this.jobsService.currentJob.value.id,
          'conv',
          this.pendingConversations$.value[0].id
        ])
        .finally(() => {
          this.isRoutingToNewConv.next(false);
        });

      return;
    } else if (
      nextOrNew === 'next' &&
      this.actionableConversations.value.length > 0
    ) {
      // the agent is in reply mode and needs to go to the next actionable convo rather than
      // a new draft.
      console.log(
        'Agent already has an actionable conversation available for start new'
      );

      // On some slow computers routing to a new conversation was taking longer than
      // the delay interval between messages. This was causing the startNewConv
      // function to loop routing to the "next" conversation and not actually ever
      // complete the route.
      // I am adding this isRouting subject to the "ready" check so a new conversation
      // request cannot be started if there is already one in progress.
      this.isRoutingToNewConv.next(true);

      // Find the index of the next convo in the inbox to route to
      let i =
        this.actionableConversations.value.findIndex(
          c => this.currentConversation.value.id === c.id
        ) + 1;
      if (i === this.actionableConversations.value.length) {
        i = 0;
      }

      this.router
        .navigate([
          'accounts',
          this.authService.agent.value.aid,
          'jobs',
          this.jobsService.currentJob.value.id,
          'conv',
          this.actionableConversations.value[i].id
        ])
        .finally(() => {
          this.isRoutingToNewConv.next(false);
        });

      return;
    } else {
      console.log(
        'No pending conversations available. Setting loadNextPending True'
      );
      this.loadNextPending = true;
    }

    if (this.isFastModeEnabled.value || this.makingConvRequest.value) {
      console.log(
        'Start new conv request when isFastModeEnabled or makingConvRequest is true'
      );
      return;
    }

    console.log('adding request conversation');
    this.requestConversations.next(1);
  }

  // Added setter methods for future ability in case multiple things will access the sending param
  startSending() {
    this.sending = true;
  }

  // Added setter methods for future ability in case multiple things will access the sending param
  stopSending() {
    this.sending = false;
  }

  toggleFilterDraftMessages() {
    this.filterDraftMessages.next(!this.filterDraftMessages.value);
  }

  toggleFilterErrorMessages() {
    this.filterErrorMessages.next(!this.filterErrorMessages.value);
  }

  updatePendingConversationList(convs: Conversation[]) {
    const pc = convs
      .filter(c => [EventCssClass.new, EventCssClass.rehash].includes(c.state))
      .filter(c => !this.pendingFilters.includes(c.id));

    this.pendingConversations$.next(pc);
    console.log('pending conversations', convs, pc);
  }

  updateActionableConversationList(convs: Conversation[]) {
    // console.log('Actionable convs list:', convs.length);
    this.finishedActionableDocs = true;
    this.actionableConversations.next(convs);
  }

  updateAllConversationList(convs: Conversation[]) {
    // console.log('All convs list:', convs.length);
    this.finishedAllDocs = true;
    this.allConversations.next(convs);
  }

  updateErrorConversationList(convs: Conversation[]) {
    // console.log('Stop convs list:', convs.length);
    this.finishedErrorDocs = true;
    this.errorConversations.next(convs);
  }

  updateStopConversationList(convs: Conversation[]) {
    // console.log('Stop convs list:', convs.length);
    this.finishedStopDocs = true;
    this.stopConversations.next(convs);
  }

  updateConvLastEventContext(
    event: Event | QuestionResponseEvent,
    convId: string
  ): Promise<Event | QuestionResponseEvent> {
    const updatePayload = {
      lastEventDate: event.date,
      lastEventText: event.text
    } as Conversation;

    if (event.cssClass !== EventCssClass.info) {
      updatePayload.state = event.cssClass;
    }

    return this.afs
      .doc<Conversation>(`conversations/${convId}`)
      .update(updatePayload)
      .then(() => event);
  }

  updateConversationQuestion(
    conv: Conversation,
    question: QuestionBase<string | number>
  ): Observable<void> {
    const firstResponse = !conv.has_responses;
    const questionHasResponse = !!question.response;

    const payload = conv.questions.map(q => {
      if (q.id !== question.id) {
        return q;
      } else if (q.response === question.response) {
        return q;
      }

      // This adds the response external id to the question if the question type
      // is a select question. Non-select questions won't have a response external id.
      // This only applies if the question/job has integration details with an third party.
      if (question.controlType === 'select' && question.integration_details) {
        console.log('Processing integration select question');
        const resp = (question as SelectQuestion).responses.find(
          r => r.text === question.response
        );
        if (resp && resp.integration_details) {
          console.log('Has response integration');
          (question as SelectQuestion).response_external_id =
            resp.integration_details.external_id;
        } else if ((question as SelectQuestion).response_external_id && !resp) {
          (question as SelectQuestion).response_external_id = null;
        }
      }

      const respEvent = {
        text: `Agent marked "${question.title.slice(
          0,
          50
        )}" as responded with "${question.response}"`,
        date: firebase.firestore.FieldValue.serverTimestamp(),
        state: EventState.read,
        cssClass: EventCssClass.info,
        questionId: question.id,
        questionTitle: question.title,
        questionResponse: question.response
      } as QuestionResponseEvent;
      this.addQuestionResponseEventToConv(respEvent, conv.id);

      this.jobsService.updateJobQuestion(conv.jobId, q, question);

      return question;
    });

    conv.questions = payload;
    return this.updateConversationQuestions(
      conv.id,
      conv.jobId.id,
      payload,
      questionHasResponse,
      firstResponse
    );
  }

  updateConversationQuestions(
    convId: string,
    jobId: string,
    updatePayload: QuestionBase<string | number>[],
    hasResponses = false,
    firstResponse = false
  ): Observable<void> {
    const payload = {
      questions: updatePayload,
      has_responses: hasResponses
    } as Partial<Conversation>;

    const canvassedDisposistion = this.shouldAddCanvassedDisposition(
      hasResponses
    );
    if (canvassedDisposistion) {
      payload.disposition = canvassedDisposistion.disposition;
      payload.disposition_external_id = canvassedDisposistion.external_id;
    }
    return from(
      this.afs
        .doc<Conversation>(`conversations/${convId}`)
        .update(payload)
        .then(() => {
          if (firstResponse) {
            console.log('Updating open with responses');
            const agent = this.authService.getAgent();
            return this.updateOpenWithResponses({
              agentId: agent.id,
              jobId,
              accountId: agent.aid,
              value: 1
            })
              .catch(error => {
                console.error('Failed updating open with response', error);
              })
              .then(resp => {
                console.log(`Finished upsert open_with_responses`, resp);
              });
          }
        })
    );
  }

  private addPendingFilter(id: string) {
    this.pendingFilters.push(id);
    this.pendingFilters = this.pendingFilters.slice(-1000);
    this.updatePendingConversationList(this.pendingConversations$.value);
  }

  private incrementSendSignalCount() {
    this.sendSignalCount += 1;

    if (this.sendSignalCount > 2) {
      this.startFastMode();
    }

    if (this.sendSignalTimer) {
      clearTimeout(this.sendSignalTimer);
    }
    this.sendSignalTimer = setTimeout(this.resetSendSignalCount, 2000);
  }

  private isNonResponderEvent(eventType: EventType): boolean {
    return (
      (eventType === EventType.textEvent || eventType === EventType.mmsEvent) &&
      !this.responderService.currentAgentIsResponder.value
    );
  }

  private mapExistingQuestionResponses(c: Conversation) {
    if (c.questions) {
      c.questions = c.questions.map(q => {
        if (q.controlType !== 'select') {
          return q;
        }

        const sq: SelectQuestion = q as SelectQuestion;

        if (sq.responses && sq.responses.length > 0) {
          return sq;
        } else if (
          sq.options === undefined ||
          sq.options === null ||
          sq.options.length === 0
        ) {
          return sq;
        }

        sq.responses = sq.options.map(o => {
          return {
            id: '',
            template: '',
            text: o
          } as JobSelectQuestionResponse;
        });

        return sq;
      });
    }
  }

  // Checks the current job to see if its a VAN job and should add the Canvassed disposition
  // when a question has been answered.
  private removeConversationFromActionableList(convId: string) {
    const actionables = this.actionableConversations.value || [];
    this.actionableConversations.next(actionables.filter(c => c.id !== convId));
  }

  private resetSendSignalCount() {
    this.sendSignalCount = 0;
    this.stopFastMode();
  }

  private routeToNextConversationOrWelcome() {
    if (
      !this.isFastModeEnabled.value &&
      this.pendingConversations$.value.length > 0
    ) {
      this.startNewConv();
    } else {
      this.router.navigate([
        'accounts',
        this.authService.agent.value.aid,
        'jobs',
        this.jobsService.currentJob.value.id
      ]);
    }
  }

  private setActivePendingConversationsConnection(value: boolean) {
    if (this.activePendingConversationsConnection.value !== value) {
      this.activePendingConversationsConnection.next(value);
    }
  }

  private shouldAddCanvassedDisposition(hasResponses: boolean): JobDisposition {
    if (!hasResponses) {
      return null;
    }

    let disposition = null;
    const job = this.jobsService.getCurrentJob();
    if (
      job.integrations &&
      job.dispositions &&
      job.integrations.findIndex(i => i.thirdparty === 'van') > -1
    ) {
      disposition = job.dispositions.find(d => d.disposition === 'Canvassed');
    }
    return disposition;
  }

  private trackLeadLocalTime(lead: Lead) {
    if (lead && lead.timezone) {
      this.leadsService.setLocalTime(lead);
      if (this.localTimeInterval) {
        clearInterval(this.localTimeInterval);
      }
      this.localTimeInterval = setInterval(
        () => this.leadsService.setLocalTime(lead),
        1000
      );
    }
  }

  private unpackConversationData(
    actions: DocumentChangeAction<Conversation>[]
  ): Conversation[] {
    return actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id;
      return { id, ...data } as Conversation;
    });
  }
}
