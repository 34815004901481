import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Agent } from '../models/agent';

@Component({
  selector: 'app-home-page-default',
  templateUrl: './home-page-default.component.html',
  styleUrls: ['./home-page-default.component.scss']
})
export class HomePageDefaultComponent implements OnInit {
  @Input() agent: Agent;
  @Output() newConvo = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  createNewConv() {
    this.newConvo.emit();
  }
}
