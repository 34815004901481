import { Inject, Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import {
  BehaviorSubject,
  from,
  Observable,
  of,
  Subject,
  Subscription
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  first,
  catchError
} from 'rxjs/operators';
import { Agent } from '../models/agent';
import { Session } from '../models/session';
import { WINDOW } from '../window.provider';
import { DomainService } from './domain.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  afAuthUser$ = this.afAuth.user;
  agent: BehaviorSubject<Agent> = new BehaviorSubject<Agent>(null);
  agent$: Observable<Agent> = this.agent
    .asObservable()
    .pipe(distinctUntilChanged()) as Observable<Agent>;
  agentSubscription: Subscription;
  isAuthed = false;
  uida: string;
  session: Session;
  sessionSubscription: Subscription;
  signoutEvent = new Subject<boolean>();

  resetAgentPasswordCloudFunction = firebase
    .functions()
    .httpsCallable('agents-resetAgentPassword');

  sendPasswordResetEmailCloudFunction = firebase
    .functions()
    .httpsCallable('agents-sendPasswordResetEmail');

  getAgentCustomTokenCloudFunction = firebase
    .functions()
    .httpsCallable('agents-createCustomToken');

  constructor(
    @Inject(WINDOW) private window: Window,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private domainService: DomainService,
    private ngZone: NgZone,
    private router: Router
  ) {
    firebase.auth().onAuthStateChanged(user => {
      console.log('Auth state changed', user);
      if (!user && this.uida) {
        this.isAuthed = false;
        this.sendSignOutSignal();
      }
    });
  }

  getAgent(): Agent {
    return this.agent.value;
  }

  getAgentUILink(domain: string, path: string): Promise<string> {
    if (domain === this.domainService.getDomainUrl()) {
      return Promise.resolve(null);
    }

    const agent = this.agent.value;

    return this.getAgentCustomTokenCloudFunction({
      uid: agent.uid
    }).then(resp => {
      const token = resp.data;
      return `${this.window.location.protocol}//${domain}/accounts/${agent.aid}/adminlogin?token=${token}&path=${path}`;
    });
  }

  loginWithCustomToken(token: string, aid: string) {
    return this.afAuth.auth
      .signInWithCustomToken(token)
      .then((fbUser: firebase.auth.UserCredential) =>
        this.onLogin(fbUser.user, aid)
      );
  }

  loginWithEmailAndPassword(email: string, password: string, aid: string) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(`${aid}+${email}`, password)
      .then((fbUser: firebase.auth.UserCredential) =>
        this.onLogin(fbUser.user, aid)
      );
  }

  onLogin(fbUser: firebase.User, accountId: string, url: string = '/') {
    this.isAuthed = true;

    this.session = {
      uid: fbUser.uid,
      session_id: this.afs.createId()
    } as Session;

    if (!this.domainService.domain.value) {
      console.log('Domain value not set yet');
      this.domainService
        .fetchDomainData(this.domainService.getAccountDomainString(accountId))
        .pipe(
          catchError(() =>
            this.domainService.fetchDomainData(
              this.domainService.getDomainUrl()
            )
          ),
          first()
        )
        .subscribe();
    }

    this.afs
      .doc(`sessions/${fbUser.uid}`)
      .set(this.session)
      .then(() => {
        this.sessionSubscription = this.afs
          .doc<Session>(`sessions/${fbUser.uid}`)
          .valueChanges()
          .subscribe(sess => {
            if (
              sess &&
              this.session &&
              sess.session_id !== this.session.session_id
            ) {
              console.log('Invalid session. Signing out.');
              this.sendSignOutSignal();
            }
          });
      });

    this.agentSubscription = this.getOrCreateAgent(fbUser, accountId)
      .pipe(
        switchMap((uida: string) => this.subscribeToAgent(uida)),
        catchError(() => this.router.navigateByUrl('/error'))
      )
      .subscribe(() => {
        this.ngZone.run(() => this.router.navigateByUrl(url));
      });
  }

  resetPassword(
    code: string,
    email: string,
    password: string,
    rootDomain: string
  ): Promise<firebase.functions.HttpsCallableResult> {
    return this.resetAgentPasswordCloudFunction({
      code,
      email,
      password,
      rootDomain
    });
  }

  sendPasswordResetEmail(
    email: string,
    accountId: string,
    rootDomain: string
  ): Promise<firebase.functions.HttpsCallableResult> {
    return this.sendPasswordResetEmailCloudFunction({
      email,
      accountId,
      rootDomain
    });
  }

  signOut() {
    if (this.agentSubscription) {
      this.agentSubscription.unsubscribe();
    }
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
    }
    this.session = null;
    this.agent.next(null);
    this.uida = null;
    this.isAuthed = false;

    return this.afAuth.auth
      .signOut()
      .then(() => console.log('Auth service signed out'));
  }

  signUpWithEmailAndPassword(
    aid: string,
    display_email: string,
    password: string,
    first_name: string,
    last_name?: string
  ) {
    console.log('Creating new user');
    const email = `${aid}+${display_email}`;
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((fbUserCreds: firebase.auth.UserCredential) => {
        const fbUser = fbUserCreds.user;
        return this.updateDisplayName(fbUser, first_name, last_name).then(
          () => {
            const uida = `${fbUser.uid}@${aid}`;
            const agentDocument = this.afs.doc<Agent>(`agents/${uida}`);
            return this.createAgentFromEmailAndPassword(
              fbUser,
              aid,
              agentDocument,
              first_name,
              last_name,
              display_email
            ).then(() =>
              this.loginWithEmailAndPassword(display_email, password, aid)
            );
          }
        );
      });
  }

  private createAgent(
    first_name,
    last_name,
    display_name,
    email,
    display_email,
    aid,
    uid,
    agentDocument,
    id_token?,
    refresh_token?,
    token_expires_at?
  ): Observable<string> {
    console.log('Creating new agent');
    const uida = `${uid}@${aid}`;
    const domain = this.domainService.getDomainUrl();
    return from(
      agentDocument
        .set({
          aid,
          first_name,
          last_name,
          display_name,
          domain,
          email,
          display_email,
          uid,
          uida,
          id: uida,
          status: 'active',
          id_token,
          refresh_token,
          token_expires_at
        })
        .then(() => uida)
    ) as Observable<string>;
  }

  private createAgentFromEmailAndPassword(
    authUser,
    aid,
    agentDocument,
    first_name,
    last_name,
    display_email
  ): Promise<string> {
    console.log('Creating new agent from email and password');
    return authUser
      .getIdTokenResult()
      .then(res =>
        this.createAgent(
          first_name,
          last_name,
          authUser.displayName,
          authUser.email,
          display_email,
          aid,
          authUser.uid,
          agentDocument,
          res.token,
          authUser.refreshToken,
          Date.parse(res.expirationTime)
        ).toPromise()
      );
  }

  private createAgentFromLogin(authUser, aid, agentDocument) {
    console.log('Creating new agent from social login');
    const [first_name, ...rest] = authUser.displayName.split(' ');
    const last_name = rest.join(' ');
    return this.createAgent(
      first_name,
      last_name,
      authUser.displayName,
      authUser.email,
      authUser.email,
      aid,
      authUser.uid,
      agentDocument
    );
  }

  private getOrCreateAgent(
    fbUser: firebase.User,
    accountId: string
  ): Observable<string> {
    console.log(`Getting or creating agent`, fbUser);
    const uida = `${fbUser.uid}@${accountId}`;
    this.uida = uida;
    console.log('uida set');
    const agentDocument = this.afs.doc<Agent>(`agents/${uida}`);
    return agentDocument.get().pipe(
      switchMap(snapshot => {
        if (snapshot.exists) {
          console.log('Agent exists');
          return of(uida);
        }

        return this.createAgentFromLogin(fbUser, accountId, agentDocument);
      })
    );
  }

  private sendSignOutSignal() {
    this.signoutEvent.next(true);
  }

  private updateDisplayName(
    fbUser: firebase.User,
    first_name: string,
    last_name?: string
  ): Promise<void> {
    console.log('Adding display name to new user');
    const displayName = last_name ? `${first_name} ${last_name}` : first_name;
    return fbUser.updateProfile({ displayName });
  }

  private setAgent(agent: Agent) {
    console.log('Setting agent');
    this.uida = agent.uida;
    this.agent.next(agent);
  }

  private subscribeToAgent(uida: string) {
    return this.afs
      .doc<Agent>(`agents/${uida}`)
      .valueChanges()
      .pipe(
        distinctUntilChanged(),
        map(agent => {
          console.log('Got agent sub', agent);

          if (agent.status !== 'active') {
            console.log('Agent is no longer active');
            return this.sendSignOutSignal();
          }
          this.setAgent(agent);
        })
      );
  }
}
