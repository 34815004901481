import 'firebase/functions';

import * as Bowser from 'bowser';
import * as firebase from 'firebase/app';

import { AgentStatus } from '../models/agent';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { TimeService } from './time.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  agentStatusDocRef = this.afs.doc(`agentStatus/${this.authService.uida}`);
  agentStatusUpdateInterval;
  currentJobId: string;
  currentStatus: AgentStatus = {} as AgentStatus;
  hasInitialized = false;
  hasPerformedAction = false;
  inactivityTimer: NodeJS.Timeout;
  isFastModeEnabled = false;
  lastActionData: Partial<AgentStatus>;

  updateAgentStatusCloudFunction = firebase
    .functions()
    .httpsCallable('stats-updateAgentStatus');

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private timeService: TimeService
  ) {
    this.resetCurrentStatus = this.resetCurrentStatus.bind(this);
    this.updateAgentStatus = this.updateAgentStatus.bind(this);
    this.authService.agent.subscribe(agent => {
      if (agent) {
        this.initCurrentStatus();
        this.performedAction('login');
        this.startStatusUpdates();
      }
    });
  }

  getCurrentStatus(withLastAction = false) {
    let status = Object.assign(
      {},
      { ...this.currentStatus },
      { is_using_sanm: this.isFastModeEnabled }
    );
    if (this.hasPerformedAction || withLastAction) {
      status = Object.assign({ ...status }, { ...this.lastActionData });
    }
    return status;
  }

  performedAction(action: string, options?: Partial<AgentStatus>) {
    this.hasPerformedAction = true;
    this.resetInactivityTimer();

    if (action === 'changed current job') {
      this.currentStatus.just_changed_jobs = true;
      this.currentStatus.previous_job_id = options.previous_job_id;
      this.currentStatus.current_job_id = options.current_job_id;
    }

    this.lastActionData = {
      is_active: true,
      last_action: action,
      last_action_date: this.timeService.now.getTime(),
      last_action_job_id: this.currentJobId || null,
      transferredConversations: false
    };
  }

  setCurrentJobId(id: string) {
    this.currentJobId = id;
  }

  startStatusUpdates() {
    if (this.agentStatusUpdateInterval) {
      clearInterval(this.agentStatusUpdateInterval);
    }
    this.updateAgentStatus();
    this.agentStatusUpdateInterval = setInterval(
      this.updateAgentStatus,
      1000 * 10
    );
    this.hasInitialized = true;
  }

  async signOut(uida?: string) {
    clearInterval(this.agentStatusUpdateInterval);
    this.hasInitialized = false;
    if (this.authService.isAuthed) {
      await this.updateAgentStatusCloudFunction({
        is_active: false,
        is_logged_in: false,
        transferredConversations: false,
        just_logged_out: true,
        last_login_date: this.timeService.now.getTime(),
        last_login_check: this.timeService.now.getTime(),
        last_action: 'logout',
        last_action_date: this.timeService.now.getTime(),
        uida
      }).catch(err => {
        console.error(err);
        throw err;
      });
    }
    console.log('Stats service signed out');
  }

  private initCurrentStatus() {
    const agent = this.authService.agent.value;
    this.currentStatus = {
      account_id: agent.aid,
      uida: agent.uida,
      agent_ui_version: environment.version,
      environment: Bowser.parse(window.navigator.userAgent),
      last_login_date: this.timeService.now.getTime(),
      last_login_check: this.timeService.now.getTime(),
      is_using_sanm: this.isFastModeEnabled,
      is_logged_in: true,
      is_active: true,
      just_logged_in: true
    };
  }

  private resetCurrentStatus() {
    this.hasPerformedAction = false;
    this.currentStatus.is_active = false;
    this.currentStatus.just_changed_jobs = false;
    this.currentStatus.just_logged_in = false;
    this.currentStatus.just_went_inactive = false;

    delete this.currentStatus.current_job_id;
    delete this.currentStatus.previous_job_id;
  }

  private resetInactivityTimer() {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }

    this.inactivityTimer = setTimeout(() => {
      this.currentStatus.just_went_inactive = true;
    }, 5 * 60 * 1000);
  }

  private updateCurrentStatus() {
    this.currentStatus.last_login_check = this.timeService.now.getTime();
    this.currentStatus.is_using_sanm = this.isFastModeEnabled;
  }

  private updateAgentStatus() {
    // console.log('Updating agent status');
    if (!this.currentStatus) {
      this.resetCurrentStatus();
    }

    this.updateCurrentStatus();

    this.updateAgentStatusCloudFunction(this.getCurrentStatus())
      .then(() => {
        // console.log('Updated agent status via cloud function.');
        this.resetCurrentStatus();
      })
      .catch(error =>
        console.error(
          'Failed to update agent status via cloud function: ',
          error
        )
      );
  }
}
