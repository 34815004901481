<div
  class="d-flex flex-column justify-content-center align-items-center h-100 text-center"
>
  <div
    *ngIf="domain$ | async as domain; else loading"
    id="main-container"
    class="my-auto d-flex flex-column align-items-center px-3"
  >
    <img class="logo" [src]="domain.logo" />

    <h2 class="header-text account-name">{{ domain.account_name }}</h2>

    <div class="form-section">
      <h4 class="header-text mb-3">{{ 'reset.trouble' | translate }}</h4>
      <p class="header-text">{{ 'reset.email' | translate }}</p>

      <form
        class="d-flex flex-column align-items-center"
        [formGroup]="form"
        (ngSubmit)="onSubmit(domain.aid)"
      >
        <label for="email" class="sr-only">
          {{ 'common.email' | translate }}
        </label>
        <input
          autofocus
          class="form-control text-box"
          formControlName="email"
          id="email"
          [placeholder]="'common.email' | translate"
          required
          type="email"
        />

        <p *ngIf="success" class="mt-3 text-success">
          {{ 'reset.checkEmail' | translate }}
        </p>

        <p *ngIf="error" class="mt-3 text-danger">
          {{ 'alert.serviceUnavailable' | translate }}
        </p>

        <button
          class="submit-btn btn btn-lg w-100"
          [disabled]="!form.valid || submitting"
          type="submit"
        >
          <span *ngIf="!submitting">{{ 'common.submit' | translate }}</span>
          <app-spinner *ngIf="submitting" width="2rem"></app-spinner>
        </button>

        <a class="back-to-login mt-3" [routerLink]="['../login']">{{
          'reset.backToLogin' | translate
        }}</a>
      </form>
    </div>
  </div>

  <ng-template #loading>
    <h2 class="my-auto">{{ 'common.loading' | translate }}...</h2>
  </ng-template>

  <app-footer></app-footer>
</div>
