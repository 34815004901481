import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { EventType, MMSEvent, TextEvent } from '../models/event';
import { FormControl, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AlertsService } from '../services/alerts.service';
import { ConvsService } from '../services/convs.service';
import { DomainService } from '../services/domain.service';
import { ResponderService } from '../services/responder.service';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { KeyClickService } from '../services/keyclick.service';
import { Lead } from '../models/leads';
import { MediaPreviewComponent } from '../media-preview/media-preview.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateMedia } from '../models/job';
import { isMobile } from '../utils/screens';

@Component({
  selector: 'app-conv-actions-footer-default',
  templateUrl: './conv-actions-footer-default.component.html',
  styleUrls: ['./conv-actions-footer-default.component.scss']
})
export class ConvActionsFooterDefaultComponent
  implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('inputRow', { static: true }) inputRow: ElementRef;
  @ViewChild('textarea', { static: true }) textarea: ElementRef;

  @Output() gotoEvent = new EventEmitter<string>();
  @Output() textEvent = new EventEmitter<TextEvent | MMSEvent>();

  @Input() lead: Lead;
  @Input() sendButtonStatus: string;

  data = { canEditMessages: false, canSendMessages: true };
  emojiPickerIsOpen = false;
  sendAndNewMouseDownTimer: NodeJS.Timeout;
  isCollapsed = true;
  subs: Subscription[] = [];
  sendAndNewClickAndHold = false;
  text: FormControl;
  templateText: string;
  templateMedia: TemplateMedia;

  canEditMessages$ = combineLatest([
    this.domainService.systemConfigs,
    this.responderService.currentAgentIsResponder
  ]).pipe(
    map(([config, currentAgentIsResponder]) => {
      if (config.can_edit_messages === 'ALL') {
        return true;
      } else if (
        config.can_edit_messages === 'RESPONDERS' &&
        currentAgentIsResponder
      ) {
        return true;
      }

      return false;
    })
  );

  canSendMessages$ = this.convsService.canSendMessages$;

  clearTemplate$ = this.convsService.clearTemplate$.pipe(
    tap(template => {
      if (template === this.text.value) {
        this.injectTemplate('');
      }
    })
  );
  focusOnRouting$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    tap(e => {
      if (!isMobile()) {
        this.textarea.nativeElement.focus();
      }
    })
  );

  constructor(
    private alertService: AlertsService,
    private convsService: ConvsService,
    private cd: ChangeDetectorRef,
    private domainService: DomainService,
    private responderService: ResponderService,
    private keyClickService: KeyClickService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  get sendButtonText() {
    if (this.text.value) {
      if (this.sendButtonStatus === 'new') {
        return 'sendAndNew';
      }
      return 'sendAndNext';
    } else {
      if (this.sendButtonStatus === 'new') {
        return 'new';
      }
      return 'next';
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => {
      if (s) {
        s.unsubscribe();
      }
    });

    document.removeEventListener('keydown', this.handleKeyClick);
  }

  ngOnInit() {
    this.handleKeyClick = this.handleKeyClick.bind(this);
    this.buildForm();
    this.autoResizeTextarea();
    this.startKeyClickListener();
    this.subscribeToDataStreams();
  }

  ngAfterViewInit() {
    this.forceTextareaResize();
  }

  clearMedia() {
    this.templateMedia = null;
    this.forceTextareaResize();
  }

  injectTemplateText(text: string) {
    this.templateText = text;
    this.text.reset(text);
    if (!isMobile()) {
      this.textarea.nativeElement.focus();
    }
  }

  injectTemplate(text: string, media: TemplateMedia = null) {
    this.injectTemplateText(text);
    this.templateMedia = media;
    this.forceTextareaResize();
  }

  onGoTo(comp: string) {
    this.gotoEvent.emit(comp);
  }

  onSendMessage(thenStartNew: boolean = false) {
    if (!this.convsService.canSendMessage()) {
      console.log('Conv service is not ready');
      return;
    }

    if (this.text.value && this.text.valid) {
      const txt = this.stripAndValidateMessage(this.text);
      if (!txt) {
        return;
      }

      if (this.templateMedia) {
        console.log('Has media');
        if (this.templateMedia.media_id) {
          console.log('has new media');
          this.textEvent.emit({
            messageType: EventType.mmsEvent,
            text: txt,
            thenStartNew,
            media_id: this.templateMedia.media_id,
            preview_url: this.templateMedia.preview_url,
            thumbnail_url: this.templateMedia.thumbnail_url,
            media_type: this.templateMedia.media_type,
            title: this.templateMedia.title
          } as MMSEvent);
        } else {
          console.log('has old media');
          this.textEvent.emit({
            messageType: EventType.mmsEvent,
            text: txt,
            thenStartNew,
            media: this.templateMedia.url,
            media_type: 'IMAGE',
            title: this.templateMedia.title
          } as MMSEvent);
        }
      } else {
        console.log('has no media');
        this.textEvent.emit({
          messageType: EventType.textEvent,
          text: txt,
          thenStartNew
        } as TextEvent);
      }

      this.resetForm();
    } else if (!this.text.value && thenStartNew) {
      this.convsService.startNewConv(this.sendButtonStatus);
    }
  }

  openEmojiPicker() {
    this.emojiPickerIsOpen = true;
  }

  onEmojiSelected(emoji: EmojiData) {
    const input = this.textarea.nativeElement;
    input.focus();

    if (document.execCommand) {
      const event = new Event('input');
      document.execCommand('insertText', false, emoji.native);
    } else {
      const [start, end] = [input.selectionStart, input.selectionEnd];
      input.setRangeText(emoji.native, start, end, 'end');
    }
    this.emojiPickerIsOpen = false;
  }

  previewMedia() {
    if (!this.templateMedia) {
      return;
    }

    const modalRef = this.modalService.open(MediaPreviewComponent, {
      centered: true,
      windowClass: 'media-preview-modal'
    });
    this.setModalPreviewValues(modalRef.componentInstance);
  }

  resetForm(text: string = null) {
    this.text.reset(text);
    this.forceTextareaResize();
    this.clearMedia();
  }

  private autoResizeTextarea() {
    this.textarea.nativeElement.addEventListener(
      'input',
      () => this.forceTextareaResize(),
      false
    );
  }

  private buildForm() {
    // console.log('Building text input form');
    this.text = new FormControl(this.templateText, [Validators.required]);
  }

  private forceTextareaResize() {
    this.textarea.nativeElement.style.height = `auto`;
    this.textarea.nativeElement.style.height = `${this.textarea.nativeElement.scrollHeight}px`;
    this.cd.detectChanges();
  }

  private handleKeyClick(event: KeyboardEvent) {
    this.keyClickService.handleKeyClick(event);
    const ctrlAndEnter = this.keyClickService.hasCtrlAndEnter();
    const enterAndNotShift =
      this.keyClickService.clickedKey('Enter') &&
      !this.keyClickService.clickedKey('Shift');

    if (enterAndNotShift || ctrlAndEnter) {
      this.onSendMessage(ctrlAndEnter);
      event.preventDefault();
    } else if (
      this.keyClickService.clickedKey('Control') &&
      this.keyClickService.clickedKey('Shift')
    ) {
      event.preventDefault();
    }
  }

  private setModalPreviewValues(comp) {
    comp.type = this.templateMedia.media_id
      ? this.templateMedia.media_type
      : 'IMAGE';
    comp.url = this.templateMedia.media_id
      ? this.templateMedia.preview_url
      : this.templateMedia.url;
    comp.title = this.templateMedia.title;
  }

  private startKeyClickListener() {
    document.addEventListener('keydown', this.handleKeyClick);
  }

  private stripAndValidateMessage(text: FormControl): string | null {
    const txt = text.value.trim();
    if (!txt) {
      this.alertService.setMessage('alert.blankMessage');
      return null;
    }
    return txt;
  }

  private subscribeToDataStreams() {
    this.subs.push(this.focusOnRouting$.subscribe());

    this.subs.push(
      combineLatest([
        this.canEditMessages$,
        this.canSendMessages$,
        this.clearTemplate$
      ]).subscribe(([canEditMessages, canSendMessages, clearTemplate]) => {
        console.log('oh hoy!!!', this.data);
        this.data = {
          canEditMessages,
          canSendMessages
        };
      })
    );
  }
}
