import { Observable, from } from 'rxjs';

import { AngularFirestore } from '@angular/fire/firestore';
import { DNCRequest } from '../models/dnc';
import { Injectable } from '@angular/core';
import { Lead } from '../models/leads';
import { OptOutEvent } from '../models/event';
import { TimeService } from './time.service';
import { map } from 'rxjs/operators';
import { timezones } from '../utils/timezones';
import { utcToZonedTime } from 'date-fns-tz';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  constructor(
    private afs: AngularFirestore,
    private timeService: TimeService
  ) {}

  optOutLead(event: OptOutEvent): Observable<OptOutEvent> {
    return from(
      this.afs.collection<DNCRequest>('dncRequest').add({
        phone: event.phone,
        account_id: event.account_id,
        conversation_id: event.conversation_id
      })
    ).pipe(map(() => event));
  }

  setLocalTime(lead: Lead) {
    try {
      lead.localtime = utcToZonedTime(
        this.timeService.now,
        timezones[lead.timezone]
      );
    } catch (error) {
      console.warn('Could not convert lead timezone', { error });
      lead.localtime = this.timeService.now;
    }
    lead.isOutsideHours = this.isOutsideHours(lead.localtime);
  }

  isOutsideHours(date: Date): string {
    const hr = date.getHours();
    if (hr <= 7) {
      return 'before';
    } else if (hr >= 21) {
      return 'after';
    }
    return null;
  }
}
