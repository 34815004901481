import * as firebase from 'firebase/app';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  CompletedEvent,
  EventCssClass,
  EventState,
  MMSEvent,
  OptOutEvent,
  TextEvent,
  TransferEvent
} from '../models/event';

import { AuthService } from '../services/auth.service';
import { ConvActionsFooterDefaultComponent } from '../conv-actions-footer-default/conv-actions-footer-default.component';
import { Conversation } from '../models/conversation';
import { JobsService } from '../services/jobs.service';
import { Subscription } from 'rxjs';
import { Template } from '../models/job';
import { TemplateService } from '../services/template.service';

@Component({
  selector: 'app-conv-actions-footer',
  templateUrl: './conv-actions-footer.component.html',
  styleUrls: ['./conv-actions-footer.component.scss']
})
export class ConvActionsFooterComponent implements OnChanges, OnInit {
  @ViewChild('actionsFooterDefault', { static: false })
  actionsDefaultFooter: ConvActionsFooterDefaultComponent;

  @Output() event = new EventEmitter<
    CompletedEvent | TextEvent | MMSEvent | TransferEvent | OptOutEvent
  >();

  @Input() conversation: Conversation;
  @Input() sendButtonStatus: string;

  currentlyDisplayedComponent = 'default';
  subs: Subscription[] = [];
  templateText: string;
  templateMediaUrl: string;
  templateMediaThumbnailUrl: string;

  constructor(
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private jobsService: JobsService,
    private templateService: TemplateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.conversation) {
      // console.log('Conversation changes', changes);
      if (
        (this.conversation.state === EventCssClass.new ||
          this.conversation.state === EventCssClass.rehash) &&
        this.isDifferentConversation(changes)
      ) {
        // console.log('Empty conversation, adding default template');
        this.insertDefaultTemplate();
      } else {
        // console.log('Not empty conversation, nulling template text');
        this.templateText = null;
        // console.log('Not first conversation load');
        if (
          this.actionsDefaultFooter &&
          changes.conversation.previousValue.id !==
            changes.conversation.currentValue.id
        ) {
          this.actionsDefaultFooter.injectTemplate(null);
        }
      }
    }
  }

  ngOnInit() {}

  insertDefaultTemplate() {
    // console.log('Insert default template');
    if (this.jobsService.getCurrentJob()) {
      const template = this.jobsService
        .getCurrentJob()
        .templates.find(t => t.is_default);
      this.onSelectedTemplate(template);
    }
  }

  onGoTo(comp: string) {
    this.currentlyDisplayedComponent = comp;
  }

  onSendMessage(event: TextEvent | MMSEvent) {
    event.agent = this.authService.agent.value;
    event.date = firebase.firestore.FieldValue.serverTimestamp();
    if (this.jobsService.getCurrentJob()) {
      event.account_id = this.jobsService.getCurrentJob().account_id;
    }
    event.state = EventState.pending;
    event.cssClass = EventCssClass.agent;
    console.log(event);
    this.event.emit(event);
  }

  resetMessageInputForm(text: string = null) {
    // console.log('Resetting message input from actions footer');
    if (this.actionsDefaultFooter) {
      this.actionsDefaultFooter.resetForm(text);
    }
  }

  onSelectedTemplate(template: Template) {
    const text = this.templateService.buildMessage(
      template,
      this.conversation.lead,
      this.authService.agent.value
    );
    this.currentlyDisplayedComponent = 'default';
    this.cd.detectChanges();

    this.actionsDefaultFooter.injectTemplate(text, template.media);
  }

  private isDifferentConversation(changes: SimpleChanges): boolean {
    if (!changes.conversation || !changes.conversation.previousValue) {
      return true;
    }
    return (
      changes.conversation.previousValue.id !==
      changes.conversation.currentValue.id
    );
  }
}
