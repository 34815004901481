import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { distinctUntilKeyChanged, map, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { Job } from '../models/job';
import { JobsService } from '../services/jobs.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentJobGuard implements CanActivateChild {
  constructor(
    private authService: AuthService,
    private jobsService: JobsService,
    private router: Router
  ) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log('Current job guard init');
    return this.jobsService.currentJob$.pipe(
      switchMap(currentJob => {
        if (currentJob && currentJob.id === next.params.jobid) {
          console.log(
            'Current job already same as navigating to job. Passing current job guard.',
            { currentJob: currentJob.id, nextJob: next.params.jobid }
          );
          return of(true);
        }
        return this.jobsService.availableJobs$.pipe(
          map((jobs: Job[]) =>
            jobs.find((j: Job) => j.id === next.params.jobid)
          ),
          map(job => {
            if (job) {
              console.log('Setting current job and passing current job guard.');
              this.jobsService.setCurrentJob(job.id);
              return true;
            }
            console.log(
              'Failing current job guard and routing to default job.'
            );
            return this.router.createUrlTree([
              'accounts',
              this.authService.agent.value.aid,
              'jobs',
              'default'
            ]);
          })
        );
      })
    );
  }
}
