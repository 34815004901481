import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Conversation } from '../models/conversation';
import { ConvsService } from '../services/convs.service';
import { JobsService } from '../services/jobs.service';
import { SidebarService } from '../services/sidebar.service';

@Component({
  selector: 'app-sidebar-conv-list',
  templateUrl: './sidebar-conv-list.component.html',
  styleUrls: ['./sidebar-conv-list.component.scss']
})
export class SidebarConvListComponent implements OnInit {
  @Input() bottom = false;
  @Input() convs: Conversation[] = [];
  @Input() loading = false;
  @Output() scrolled = new EventEmitter();

  constructor(
    public sidebarService: SidebarService,
    public jobsService: JobsService,
    public convsService: ConvsService
  ) {}

  ngOnInit() {}

  onScroll() {
    this.scrolled.emit();
  }
}
