import { Component, OnInit } from '@angular/core';

import { DomainService } from './services/domain.service';
import { LanguageService } from './services/language.service';
import { TimeService } from './services/time.service';
import { UserSettingsService } from './services/user-settings.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentUI = environment.version;
  // TODO: This was changed to beta_ui for now, but will need to be changed to correctly check for beta or prod
  minimumUi$ = this.domainService.systemConfigs.pipe(
    map(configs => configs.minimum_beta_ui_version)
  );
  // TODO: This was changed to beta_ui for now, but will need to be changed to correctly check for beta or prod
  showReload$ = this.domainService.systemConfigs.pipe(
    map(configs => configs.minimum_beta_ui_version && !configs.has_minimum_ui)
  );

  constructor(
    private domainService: DomainService,
    public timeService: TimeService,
    public languageService: LanguageService,
    public userSettingsService: UserSettingsService
  ) {}

  ngOnInit() {}
}
