import * as i18n from '../../assets/i18n/en.json';

import { Conversation } from '../models/conversation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const transferRE = /Conversation has been transferred from (.+) to (.+)/;
const nonResponderRE = /(.+) has transfered this conversation to agent (.+)/;
const questionRE = /Agent marked (.+) as responded with (.+)/;

export function parseTransferEventText(
  text: string
): { toAgent: string; fromAgent: string } {
  let match = text.match(transferRE);

  if (!match) {
    match = text.match(nonResponderRE);
  }

  if (!match) {
    match = [null, 'agent', 'agent'];
  }

  return { fromAgent: match[1], toAgent: match[2] };
}

export function parseQuestionResponseEventText(
  text: string
): { title: string; response: string } {
  const match = text.match(questionRE);
  return { title: match[1], response: match[2] };
}

export function mapLastEventTextSystemMessages(
  convs$: Observable<Conversation[]>
): Observable<Conversation[]> {
  return convs$.pipe(
    map(convs =>
      convs.map(conv => {
        if (!conv.lastEventText) {
          return conv;
        }
        if (conv.lastEventText === i18n.convBody.completed) {
          conv.lastEventText = 'convBody.completed';
        } else if (conv.lastEventText === i18n.convBody.optOut) {
          conv.lastEventText = 'convBody.optOut';
        } else if (conv.lastEventText === i18n.convBody.rehash) {
          conv.lastEventText = 'convBody.rehash';
        } else if (conv.lastEventText === i18n.convBody.deferred) {
          conv.lastEventText = 'convBody.deferred';
        } else if (conv.lastEventText.match(transferRE)) {
          conv.translationParams = parseTransferEventText(conv.lastEventText);
          conv.lastEventText = 'convBody.transfer';
        } else if (conv.lastEventText.match(questionRE)) {
          conv.translationParams = parseQuestionResponseEventText(
            conv.lastEventText
          );
          conv.lastEventText = 'convBody.question';
        }
        return conv;
      })
    )
  );
}
