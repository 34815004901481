import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { NotificationsService } from '../services/notifications.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-activity-badge',
  templateUrl: './activity-badge.component.html',
  styleUrls: ['./activity-badge.component.scss']
})
export class ActivityBadgeComponent implements OnDestroy, OnInit {
  @Input() countType = 'inbox';

  @Input() activeTab = false;

  count: string;
  sub: Subscription;

  constructor(private notificationsService: NotificationsService) {}

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.countType === 'stops') {
      this.sub = this.notificationsService.stopsConvs$.subscribe(
        count => (this.count = count)
      );
    } else {
      this.sub = this.notificationsService.activeConvs$.subscribe(
        count => (this.count = count)
      );
    }
  }
}
