import { CommonModule } from '@angular/common';
import { DateProxyPipe } from '../pipes/date-proxy.pipe';
import { FooterComponent } from './footer/footer.component';
import { NgModule } from '@angular/core';
import { SpinnerComponent } from './spinner/spinner.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SpinnerComponent, FooterComponent, DateProxyPipe],
  exports: [SpinnerComponent, FooterComponent, DateProxyPipe],
  imports: [
    CommonModule,
    TranslateModule.forChild({ extend: true }),
    SweetAlert2Module
  ]
})
export class SharedModule {}
