import { map, tap } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';
import { Component } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';
import { LanguageService } from 'src/app/services/language.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  agent$ = this.authService.agent$;
  currentLanguage$ = this.languageService.currentLanguage$;
  currentSettings$ = this.userSettingsService.currentSettings$.pipe(
    tap(settings => console.log(settings))
  );
  domain$ = this.domainService.domain$;
  footerData$ = combineLatest([this.agent$, this.domain$]).pipe(
    map(([agent, domain]) => {
      if (agent && domain) {
        return { agentEmail: agent.display_email, appName: domain.app_name };
      }
      return null;
    })
  );

  version: string = environment.version;

  constructor(
    private authService: AuthService,
    private domainService: DomainService,
    private languageService: LanguageService,
    private userSettingsService: UserSettingsService
  ) {}

  onLanguageChange(lang: string) {
    this.languageService.setCurrentLanguage(lang);
  }

  onThemeChange(theme) {
    this.userSettingsService.setTheme(theme);
  }
}
