import { ActivatedRoute, Router } from '@angular/router';

import { AlertsService } from './alerts.service';
import { AuthService } from './auth.service';
import { ConvsService } from './convs.service';
import { Injectable } from '@angular/core';
import { JobsService } from './jobs.service';
import { ResponderService } from './responder.service';
import { StatsService } from './stats.service';
import { TransferService } from './transfer.service';

@Injectable({
  providedIn: 'root'
})
export class SignoutService {
  constructor(
    private alertsService: AlertsService,
    private authService: AuthService,
    private convsService: ConvsService,
    private jobsService: JobsService,
    private responderService: ResponderService,
    private router: Router,
    private statsService: StatsService,
    private transferService: TransferService
  ) {
    this.authService.signoutEvent.subscribe(shouldSignout => {
      if (shouldSignout) {
        console.log('Signout event triggered');
        this.signout();
      }
    });
  }

  async signout() {
    try {
      const agent = this.authService.getAgent();

      if (agent && agent.aid) {
        await this.router.navigateByUrl(`accounts/${agent.aid}/logout`);
      }

      // Adding a 3s delay before signing out so the user sits on the logout screen for a few seconds.
      // The delay prevents flashing so its not so jarring when the logout screen shows.
      setTimeout(async () => {
        await this.statsService.signOut(agent.uida);
        this.alertsService.signOut();
        this.responderService.signOut();
        this.convsService.signOut();
        this.jobsService.signOut();
        this.transferService.signOut();
        this.authService.signOut().then(() => this.router.navigateByUrl('/'));
      }, 3000);
    } catch (error) {
      console.error('Failed logging out', error);
    }
  }
}
