<div
  class="d-flex flex-column justify-content-center align-items-center h-100 bg-gray-800 text-center text-gray-300"
>
  <div
    *ngIf="!loading && !foundDomain"
    id="main-container"
    class="my-auto d-flex flex-column align-items-center px-3"
  >
    <h1 class="header-text">{{ 'common.welcome' | translate }}</h1>
    <h4 class="header-text">
      {{ 'account.enterAccountId' | translate }}
    </h4>

    <form
      class="d-flex flex-column align-items-center mx-auto"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
    >
      <label for="accountId" class="sr-only">{{
        'common.accountId' | translate
      }}</label>

      <input
        autofocus
        type="number"
        pattern="[0-9]{8}"
        inputmode="numeric"
        class="form-control"
        formControlName="accountId"
        id="accountId"
        [placeholder]="'common.accountId' | translate"
        required
      />

      <p *ngIf="error" class="mb-3 text-danger">
        {{ 'account.accountError' | translate }}
      </p>

      <button
        class="submit-btn btn btn-lg btn-block"
        [disabled]="!form.valid || submitting"
        type="submit"
      >
        <span *ngIf="!submitting">{{ 'common.submit' | translate }}</span>
        <app-spinner
          *ngIf="submitting"
          width="2rem"
          class="spinner"
        ></app-spinner>
      </button>
    </form>
  </div>

  <h2 *ngIf="loading" class="my-auto">{{ 'common.loading' | translate }}...</h2>

  <app-footer></app-footer>
</div>
