export const timezones = {
  AST: 'America/Halifax',
  ADT: 'America/Halifax',
  EST: 'America/New_York',
  EDT: 'America/New_York',
  CST: 'America/Chicago',
  CDT: 'America/Chicago',
  MST: 'America/Denver',
  MDT: 'America/Denver',
  NDT: 'America/St_Johns',
  NST: 'America/St_Johns',
  PST: 'America/Los_Angeles',
  PDT: 'America/Los_Angeles',
  AKST: 'America/Anchorage',
  AKDT: 'America/Anchorage',
  HST: 'Pacific/Honolulu',
  HAST: 'Pacific/Honolulu',
  HADT: 'America/Adak',
  SST: 'Pacific/Pago_Pago',
  SDT: 'Pacific/Pago_Pago',
  CHST: 'Pacific/Guam'
};
