import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';

import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { JobsService } from '../services/jobs.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DefaultJobGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private jobsService: JobsService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const job = this.jobsService.getDefaultJob();
    if (job) {
      console.log(`Default job guard rerouting to job ${job.id}`);
      return this.router.createUrlTree([state.url.replace('default', job.id)]);
    }
    return this.router.createUrlTree([
      'accounts',
      this.authService.agent.value.aid,
      'pending'
    ]);
  }
}
